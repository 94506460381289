import { CSSProperties, FunctionComponent, SVGProps } from 'react';
import {
  InputContainer,
  InputValueText,
  InputIconWrapper,
  InputPlaceholder,
} from './styles';

interface Props {
  value: string | number | undefined;
  onClick: () => void;
  placeholder?: string;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  style?: CSSProperties;
}

const CustomInput = ({ placeholder, onClick, value, Icon, style }: Props) => {
  return (
    <InputContainer onClick={onClick} style={style}>
      {Icon && (
        <InputIconWrapper>
          <Icon />
        </InputIconWrapper>
      )}
      {value ? (
        <InputValueText>{value}</InputValueText>
      ) : (
        <InputPlaceholder>{placeholder}</InputPlaceholder>
      )}
    </InputContainer>
  );
};

export default CustomInput;
