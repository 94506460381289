import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { forOwn } from 'lodash';
import { ENDPOINTS, axiosInstance } from 'common/axios';
import {
  CreateOfferParamsType,
  ErrorResponseType,
  SearchOfferParamsType,
  SearchOffersApiResponse,
} from 'common/types';

export const searchOffersThunk: AsyncThunkPayloadCreator<
  SearchOffersApiResponse,
  SearchOfferParamsType,
  { rejectValue: ErrorResponseType }
> = async (offerSearchParams, { rejectWithValue }) => {
  try {
    const params = new URLSearchParams();
    forOwn(offerSearchParams, (value, key) => {
      params.append(key, value.toString());
    });

    const response = await axiosInstance.get(ENDPOINTS.searchOffers, {
      params,
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response);
  }
};

export const getOfferDetailsThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponseType }
> = async (offerId, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.getOfferById(offerId));
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const createOfferThunk: AsyncThunkPayloadCreator<
  any,
  CreateOfferParamsType,
  { rejectValue: ErrorResponseType }
> = async (offerParams, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(
      ENDPOINTS.createOffer,
      offerParams,
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
