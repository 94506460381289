import { memo } from 'react';
import { Controller } from 'react-hook-form';
import { InputAdornment, TextField } from '@mui/material';

import {
  Label,
  InputWrapper,
  ErrorMessage,
  StyledAutocomplete,
  SChevronDownIcon,
  LocationAutocompleteIcon,
} from './styles';

const LocationAutocomplete = ({
  label,
  id,
  name,
  control,
  options,
  placeholder,
}: {
  id: string;
  name: string;
  label?: string;
  placeholder?: string;
  control: any;
  options: { id: string; name: string }[];
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { ref, value, onChange } = field;
        const selectedValue = value
          ? options.find((option) => value === option.id) ?? null
          : null;

        return (
          <InputWrapper>
            {label && <Label>{label}</Label>}
            <StyledAutocomplete
              {...field}
              id={id}
              disablePortal
              value={selectedValue}
              options={options}
              popupIcon={<SChevronDownIcon />}
              onChange={(e: any, newValue: any) => {
                onChange(newValue ? newValue.id : null);
              }}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option: any, data: any) =>
                option.id === data.id
              }
              renderInput={(params) => (
                // @ts-expect-error textfield should return error
                <TextField
                  {...params}
                  inputRef={ref}
                  error={!!error}
                  placeholder={placeholder}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <LocationAutocompleteIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                />
              )}
            />
            {error && <ErrorMessage>{error?.message}</ErrorMessage>}
          </InputWrapper>
        );
      }}
    />
  );
};

export default memo(LocationAutocomplete);
