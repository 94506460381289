export const ENDPOINTS = {
  register: '/auth/register',
  login: '/auth/login',
  refreshToken: '/auth/refresh',

  getMe: '/users/me',
  getUserById: (userId: string) => `/users/${userId}`,
  updateUserById: (userId: string) => `/users/${userId}`,
  getUserOffers: (userId: string) => `/users/${userId}/offers`,
  getUserOfferDetails: (userId: string, offerId: string) =>
    `/users/${userId}/offers/${offerId}`,
  getUserBookings: (userId: string) => `/users/${userId}/bookings`,

  getAllVehicles: '/vehicles',
  getVehicleById: (vehicleId: string) => `/vehicles/${vehicleId}`,
  createVehicle: '/vehicles',

  getAllLocations: '/locations',

  searchOffers: '/offers/search',
  getOfferById: (offerId: string) => `/offers/${offerId}`,
  createOffer: '/offers',

  getAllRequests: '/requests',
  createRequest: '/requests',
  getRequestById: (requestId: string) => `/requests/${requestId}`,

  makeBooking: '/bookings',
  getBookingById: (bookingId: string) => `/bookings/${bookingId}`,
  confirmBooking: (bookingId: string) => `/bookings/${bookingId}`,
  cancelBooking: (bookingId: string) => `/bookings/${bookingId}`,
};
