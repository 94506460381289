import { Offer } from 'common/types';
import { formatDateWithWords, formatTime } from 'helpers/date';
import { RouteSummary, Typography } from 'components';
import { OfferCardWrapper } from './styles';

interface Props {
  offer: Offer;
  onClick?: () => void;
}

const MyOfferItem = ({ offer, onClick }: Props) => {
  return (
    <OfferCardWrapper onClick={onClick}>
      <Typography type='title4' tag='span' color='secondary'>
        {formatDateWithWords(offer.departureTime)}
      </Typography>

      <div style={{ position: 'relative', left: -10 }}>
        <RouteSummary
          pickupLabel={offer.departure.district}
          dropoffLabel={offer.arrival.district}
          formattedDepartureTime={formatTime(offer.departureTime)}
        />
      </div>

      {offer.passengers.length ? (
        // display passengers here
        <div></div>
      ) : null}
    </OfferCardWrapper>
  );
};

export default MyOfferItem;
