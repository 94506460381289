import { combineReducers } from '@reduxjs/toolkit';

import {
  accountReducer,
  bookingReducer,
  locationReducer,
  modalReducer,
  offerReducer,
  offerSearchReducer,
  requestReducer,
  userReducer,
} from './slices';

const rootReducer = combineReducers({
  accountReducer,
  bookingReducer,
  locationReducer,
  modalReducer,
  offerReducer,
  offerSearchReducer,
  requestReducer,
  userReducer,
});

export const persistentStoreBlacklist = [
  'modalReducer',
  'offerReducer',
  'offerSearchReducer',
  'bookingReducer',
];

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
