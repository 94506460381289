import { ConnectionStatusWrapper } from './styles';

const ConnectionStatus = () => {
  return (
    <ConnectionStatusWrapper>
      Internet bilan aloqa mavjud emas.
    </ConnectionStatusWrapper>
  );
};

export default ConnectionStatus;
