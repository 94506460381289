import { memo } from 'react';
import {
  FooterWrapper,
  FooterContentWrapper,
  // LinkTitle,
  LogoTitle,
  // RightBlockWrapper,
} from './styles';
// import { useTranslation } from 'react-i18next';

const Footer = () => {
  // const { t } = useTranslation();
  return (
    <FooterWrapper>
      <FooterContentWrapper>
        <LogoTitle>taksifon.uz</LogoTitle>
        {/* <RightBlockWrapper>
          <LinkTitle>{t('Contact us')}</LinkTitle>
        </RightBlockWrapper> */}
      </FooterContentWrapper>
    </FooterWrapper>
  );
};

export default memo(Footer);
