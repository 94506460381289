import styled from 'styled-components';
import { BottomNavigationAction, Paper } from '@mui/material';
import { FONTS } from 'styles';
import { GREY_400, PRIMARY_BLUE_300 } from 'styles/colors';
import { RidesIcon } from 'assets/svg';

export const NAVIGATION_BAR_HEIGHT = 56;

export const StyledPaper = styled(Paper)`
  max-height: ${NAVIGATION_BAR_HEIGHT}px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const StyledBottomNavigationAction = styled(BottomNavigationAction)<{
  selected?: boolean;
}>`
  padding: 0 !important;
  min-width: 60px !important;
  & .Mui-selected {
    color: ${PRIMARY_BLUE_300};
    font-size: 0.625rem !important;
  }
  & .MuiBottomNavigationAction-label {
    font-size: 0.625rem;
    font-family: ${FONTS.Helvetica.medium};
  }
  svg {
    width: 25px;
    height: 25px;
  }
  path {
    stroke: ${({ selected }) => (selected ? PRIMARY_BLUE_300 : GREY_400)};
  }
`;

export const FilledRidesIcon = styled(RidesIcon)`
  path {
    fill: ${PRIMARY_BLUE_300};
  }
`;
