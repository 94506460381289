import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { getIsLoggedIn } from 'common/store/selectors';
import AnimatedRoutes from 'styles/AnimatedRoutes';
import { Layout } from 'components';
import { ROUTES } from './Routes';
import {
  AccountDetailsPage,
  AccountEditPage,
  AccountPage,
  AddVehiclePage,
  CreateOfferPage,
  CreateRequestPage,
  HomePage,
  LoginPage,
  MyRideDetailsPage,
  MyRidesPage,
  OfferDetailsPage,
  OfferListingPage,
  SearchPage,
  ServicesPage,
  UserDetailsPage,
} from 'pages';

const AppRouter = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);

  return (
    <Layout>
      <AnimatedRoutes>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        {isLoggedIn && (
          <>
            <Route path={ROUTES.ACCOUNT} element={<AccountPage />}>
              <Route index element={<AccountDetailsPage />} />
              <Route
                path={ROUTES.ACCOUNT_ADD_VEHICLE}
                element={<AddVehiclePage />}
              />
              <Route path={ROUTES.ACCOUNT_EDIT} element={<AccountEditPage />} />
            </Route>
            <Route path={ROUTES.USER_DETAILS} element={<UserDetailsPage />} />
            <Route path={ROUTES.SERVICES} element={<ServicesPage />} />
            <Route path={ROUTES.CREATE_OFFER} element={<CreateOfferPage />} />
            <Route
              path={ROUTES.CREATE_REQUEST}
              element={<CreateRequestPage />}
            />
          </>
        )}
        <Route path={ROUTES.SEARCH} element={<SearchPage />} />
        <Route path={ROUTES.MY_RIDES} element={<MyRidesPage />} />
        <Route path={ROUTES.MY_RIDE_DETAILS} element={<MyRideDetailsPage />} />
        <Route path={ROUTES.OFFER_LISTING} element={<OfferListingPage />} />
        <Route path={ROUTES.OFFER} element={<OfferDetailsPage />} />
        <Route path='*' element={<p>Not Found page</p>} />
      </AnimatedRoutes>
    </Layout>
  );
};

export default AppRouter;
