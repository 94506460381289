import { memo } from 'react';
import { Controller } from 'react-hook-form';

import {
  CounterButton,
  CounterContainer,
  CounterCount,
  ErrorMessage,
  InputWrapper,
  Label,
} from './styles';

const CounterInputController = ({
  control,
  name,
  label,
}: {
  control: any;
  name: string;
  label?: string;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputWrapper>
            <Label>{label}</Label>
            <CounterContainer>
              <CounterButton
                disabled={field.value === 1}
                onClick={() => field.onChange(field.value - 1)}
              >
                -
              </CounterButton>
              <CounterCount>{field.value}</CounterCount>
              <CounterButton
                disabled={field.value === 9}
                onClick={() => field.onChange(field.value + 1)}
              >
                +
              </CounterButton>
            </CounterContainer>
            {error && <ErrorMessage>{error?.message}</ErrorMessage>}
          </InputWrapper>
        );
      }}
    />
  );
};

export default memo(CounterInputController);
