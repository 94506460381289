import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BottomNavigation } from '@mui/material';

import {
  AccountIcon,
  HomeIcon,
  PlusIcon,
  RidesIcon,
  SearchIcon,
} from 'assets/svg';
import {
  FilledRidesIcon,
  StyledBottomNavigationAction,
  StyledPaper,
} from './NavigationBar.styles';
import { ROUTES } from 'router';

const NavigationBar = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const activeTab = `/${pathname.split('/')[1]}`;

  const tabs = [
    {
      label: 'Main',
      path: ROUTES.HOME,
      icon: <HomeIcon />,
    },

    {
      label: 'Search',
      path: ROUTES.SEARCH,
      icon: <SearchIcon />,
    },
    {
      label: 'My rides',
      path: ROUTES.MY_RIDES,
      icon: pathname.includes(ROUTES.MY_RIDES) ? (
        <FilledRidesIcon />
      ) : (
        <RidesIcon />
      ),
      needLogin: true,
    },
    {
      label: 'Services',
      path: ROUTES.SERVICES,
      icon: <PlusIcon />,
      needLogin: true,
    },
    {
      label: 'Profile',
      path: ROUTES.ACCOUNT,
      icon: <AccountIcon />,
      needLogin: true,
    },
  ];

  const filteredTabs = tabs.filter(
    (tab) => !tab.needLogin || (tab.needLogin && isLoggedIn),
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (pathname !== newValue) {
      navigate(newValue);
    }
  };

  return (
    <StyledPaper elevation={3}>
      <BottomNavigation showLabels value={activeTab} onChange={handleChange}>
        {filteredTabs.map((tab, index) => {
          return (
            <StyledBottomNavigationAction
              key={index}
              label={t(`${tab.label}`)}
              icon={tab.icon}
              value={tab.path}
              selected={tab.path === pathname}
            />
          );
        })}
      </BottomNavigation>
    </StyledPaper>
  );
};

export default NavigationBar;
