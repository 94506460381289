import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { LocationType, StateType } from 'common/types';
import { AsyncThunks } from '../thunks';
import { onError, onPending } from '../stateResults';

type SearchHistoryType = {
  pickupLocation: LocationType;
  dropoffLocation: LocationType;
};

type LocationStateType = StateType<LocationType[]> & {
  searchHistoryList: SearchHistoryType[];
};

const initialState: LocationStateType = {
  pending: false,
  error: null,
  result: [],
  searchHistoryList: [],
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    addSearchHistory: (state, action) => {
      const alreadyExist = state.searchHistoryList.some((searchHistory) => {
        return isEqual(searchHistory, action.payload);
      });

      if (!alreadyExist) {
        state.searchHistoryList.push(action.payload);
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(AsyncThunks.getLocations.pending, onPending);
    builder.addCase(AsyncThunks.getLocations.fulfilled, (state, action) => {
      state.pending = false;
      state.result = action.payload;
    });
    builder.addCase(AsyncThunks.getLocations.rejected, onError);
  },
});

export const locationActions = locationSlice.actions;
export const locationReducer = locationSlice.reducer;
