import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { ENDPOINTS, axiosInstance } from 'common/axios';
import { ErrorResponseType } from 'common/types';

export const getUserByIdThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponseType }
> = async (userId, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.getUserById(userId));
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
