import { useNavigate } from 'react-router-dom';

import { getFullName } from 'helpers/utils';
import { BookingRequest } from 'common/types';
import Typography from 'components/Typography/Typography';
import CustomButton, { ButtonSize } from 'components/Buttons/CustomButton';
import DriverDetailsCard from 'components/DriverDetailsCard/DriverDetailsCard';
import { ButtonsWrapper, CardWrapper } from './BookingRequestItem.styles';

interface Props {
  bookingRequest: BookingRequest;
  onConfirm: () => void;
  onReject: () => void;
}

const BookingRequestItem = ({ bookingRequest, onConfirm, onReject }: Props) => {
  const navigate = useNavigate();

  return (
    <CardWrapper>
      <DriverDetailsCard
        avatar={bookingRequest.passenger.photo}
        displayName={getFullName({
          firstName: bookingRequest.passenger.firstName,
          lastName: bookingRequest.passenger.lastName,
        })}
        onClick={() => navigate(`/user/${bookingRequest.passengerId}`)}
      />

      <Typography
        type='subtitle4'
        color='secondary'
        style={{ padding: '10px 0' }}
      >
        Yo&apos;lovchilar soni: {bookingRequest.requestedSeats}
      </Typography>

      <ButtonsWrapper>
        <CustomButton inverted size={ButtonSize.Small} onClick={onReject}>
          Rad etish
        </CustomButton>

        <CustomButton size={ButtonSize.Small} onClick={onConfirm}>
          Tasdiqlash
        </CustomButton>
      </ButtonsWrapper>
    </CardWrapper>
  );
};

export default BookingRequestItem;
