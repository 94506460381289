import { createSlice } from '@reduxjs/toolkit';

import { StateType, UserType } from 'common/types';
import { onError, onPending } from '../stateResults';
import { AsyncThunks } from '../thunks';

type UserStateType = StateType<UserType | null>;

const initialState: UserStateType = {
  error: null,
  pending: false,
  result: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getUserById.pending, onPending);
    builder.addCase(AsyncThunks.getUserById.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;

      state.result = action.payload;
    });
    builder.addCase(AsyncThunks.getUserById.rejected, onError);
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
