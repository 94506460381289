import styled from 'styled-components';
import { ChevronRightIcon } from 'assets/svg';
import { FONTS } from 'styles';
import { GREY_200, GREY_300 } from 'styles/colors';

export const DriverInfoSection = styled.div`
  flex: 1 1 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RightTextWrapper = styled.span`
  flex: 1 1 0%;
  align-items: center;
  margin-left: 10px;
`;

export const DriverName = styled.span`
  color: ${GREY_300};
  font-size: 0.875rem;
  font-family: ${FONTS.Helvetica.regular};
  margin-bottom: 4px;
  display: block;
`;

export const DriverDescription = styled.span`
  color: ${GREY_200};
  font-size: 0.875rem;
  font-family: ${FONTS.Helvetica.regular};
  display: block;
`;

export const DriverAvatar = styled.div<{
  imageUrl: string;
}>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const SChevronRightIcon = styled(ChevronRightIcon)`
  width: 20px;
  height: 20px;
  margin-left: 6px;
`;
