import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getIsLoggedIn } from 'common/store/selectors';
import {
  ConnectionStatus,
  CustomModal,
  ErrorBoundary,
  Footer,
  Header,
  NavigationBar,
} from 'components';
import { NAVIGATION_BAR_HEIGHT } from 'components/NavigationBar/NavigationBar.styles';
import { useConnectionStatus, useDimensions } from 'hooks';
import { BREAKPOINTS } from 'styles';
import { GlobalStyles } from 'styles/GlobalStyles';

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.div`
  flex-grow: 1;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding-bottom: ${NAVIGATION_BAR_HEIGHT}px;
  }
`;

const Layout = ({ children }: { children: ReactNode }) => {
  const { width } = useDimensions();
  const { isOnline } = useConnectionStatus();
  const isLoggedIn = useSelector(getIsLoggedIn);

  const isMobile = width < BREAKPOINTS.xs;

  return (
    <LayoutWrapper>
      <Header isLoggedIn={isLoggedIn} />
      {!isOnline && <ConnectionStatus />}
      <ErrorBoundary>
        <Main>{children}</Main>
      </ErrorBoundary>
      <GlobalStyles />
      <CustomModal />
      {isMobile ? <NavigationBar isLoggedIn={isLoggedIn} /> : <Footer />}
    </LayoutWrapper>
  );
};

export default Layout;
