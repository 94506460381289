import { useEffect } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

import { NAVIGATION_BAR_HEIGHT } from 'components/NavigationBar/NavigationBar.styles';
import { ROUTES } from 'router';

export const AnimatedWrapper = styled.div`
  width: 100%;
  display: flex;

  &.page-enter {
    position: absolute;
    opacity: 0;
  }

  &.page-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  &.page-exit {
    opacity: 1;
  }

  &.page-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
`;

const AnimatedRoutes = ({ children }: { children: any }) => {
  const location = useLocation();
  const { state, pathname } = location;
  const prevPath: string = state?.prevPath;

  const isAnimationDisabled =
    pathname?.includes(ROUTES.OFFER_LISTING) ||
    prevPath?.includes(ROUTES.OFFER_LISTING);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <TransitionGroup>
      <CSSTransition
        key={isAnimationDisabled ? '' : location.key}
        classNames={isAnimationDisabled ? '' : 'page'}
        timeout={300}
      >
        <AnimatedWrapper
          style={{
            display: 'flex',
            minHeight: `calc(100vh - ${NAVIGATION_BAR_HEIGHT}px - 58px)`,
          }}
        >
          <Routes location={location}>{children}</Routes>
        </AnimatedWrapper>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default AnimatedRoutes;
