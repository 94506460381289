import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks';
import { getAccountDetails } from 'common/store/selectors';
import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import {
  PageTemplate,
  Typography,
  CustomNavLink,
  AccountDetailsCard,
  ItemHorizontal,
  UserPreferences,
} from 'components';
import { EditIcon, LogoutIcon, PlusIcon } from 'assets/svg';
import { getFullName, getUserRole } from 'helpers/utils';
import { BlockTitle, CarDetailsWrapper, LogoutButton } from './styles';
import { BODY } from 'styles/colors';
import { ROUTES } from 'router';

const AccountDetailsPage = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(getAccountDetails);
  const navigate = useNavigate();
  const { logoutUser } = useAuth();
  const { t } = useTranslation();

  if (!user) return null;

  const fetchUserDetails = useCallback(async () => {
    await dispatch(AsyncThunks.getMe());
  }, []);

  const logout = () => {
    navigate(ROUTES.HOME);
    logoutUser();
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <PageTemplate backgroundColor={BODY}>
      <div style={{ maxWidth: 700, margin: 'auto' }}>
        <AccountDetailsCard
          avatar={user.photo || ''}
          displayName={getFullName({
            firstName: user.firstName,
            lastName: user?.lastName,
          })}
          description={t(getUserRole(user.role))}
        />

        <CustomNavLink to={ROUTES.ACCOUNT_EDIT}>
          <ItemHorizontal
            IconLeft={<EditIcon />}
            label={t('Edit profile details')}
            isClickable
          />
        </CustomNavLink>

        <BlockTitle>Transport</BlockTitle>
        {user?.vehicle ? (
          <CarDetailsWrapper>
            <Typography type='subtitle3'>{user.vehicle.model}</Typography>
            <Typography type='body2' color='secondary'>
              {user.vehicle.number}
            </Typography>
          </CarDetailsWrapper>
        ) : (
          <CustomNavLink to={ROUTES.ACCOUNT_ADD_VEHICLE}>
            <ItemHorizontal
              IconLeft={<PlusIcon />}
              label={t('Add vehicle')}
              isClickable
            />
          </CustomNavLink>
        )}

        <BlockTitle>Siz haqingizda</BlockTitle>
        <UserPreferences
          smoking={user.smoking}
          music={user.music}
          dialog={user.dialog}
        />
        <ItemHorizontal label="O'zgartirishlar kiritish" isClickable />

        <LogoutButton onClick={logout}>
          <LogoutIcon /> {t('Logout')}
        </LogoutButton>
      </div>
    </PageTemplate>
  );
};

export default AccountDetailsPage;
