import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'router';
import { CustomButton, Typography } from 'components';
import { AddVehicleContainer } from './styles';
import { Cross } from 'pages/LoginPage/LoginPage.styles';

const AddVehicleContent = ({ closeModal }: { closeModal: () => void }) => {
  const navigate = useNavigate();

  const navigateToAddVehicle = () => {
    navigate(ROUTES.ACCOUNT_ADD_VEHICLE);
    closeModal();
  };

  return (
    <AddVehicleContainer>
      <Cross onClick={closeModal} />
      <Typography type='subtitle3'>Sizda transport mavjud emas!</Typography>
      <CustomButton onClick={navigateToAddVehicle}>
        Transport qo&apos;shish
      </CustomButton>
    </AddVehicleContainer>
  );
};

export default AddVehicleContent;
