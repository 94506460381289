import React, { Component } from 'react';
import { t } from 'i18next';

import CustomButton from 'components/Buttons/CustomButton';
import Typography from 'components/Typography/Typography';
import { Wrapper } from './ErrorBoundary.styles';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // componentDidCatch(error: Error, errorInfo: ErrorInfo)
  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Wrapper>
          <Typography tag='h1' type='title1'>
            {t('Something went wrong!')}
          </Typography>
          <CustomButton onClick={() => window.location.reload()}>
            {t('Retry')}
          </CustomButton>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
