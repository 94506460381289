import { FONTS } from 'styles';
import { GREY_300, GREY_400, PRIMARY_BLUE_300, RED_300, WHITE } from './colors';

export type TextColorType =
  | 'primary'
  | 'white'
  | 'secondary'
  | 'accent'
  | 'red';

export const TextColors = {
  primary: GREY_400,
  secondary: GREY_300,
  accent: PRIMARY_BLUE_300,
  white: WHITE,
  red: RED_300,
};

export const FONT_STYLES = {
  title1: {
    fontSize: '1.5rem',
    lineHeight: '1.8125rem',
    fontFamily: FONTS.Helvetica.bold,
  },
  title2: {
    fontSize: '1.125rem',
    lineHeight: '1.3125rem',
    fontFamily: FONTS.Helvetica.bold,
  },
  title3: {
    fontSize: '1rem',
    lineHeight: '1.1875rem',
    fontFamily: FONTS.Helvetica.bold,
  },
  title4: {
    fontSize: '0.875rem',
    lineHeight: '1.0625rem',
    fontFamily: FONTS.Helvetica.bold,
  },

  subtitle1: {
    fontSize: '1.5rem',
    lineHeight: '1.8125rem',
    fontFamily: FONTS.Helvetica.medium,
  },
  subtitle2: {
    fontSize: '1.125rem',
    lineHeight: '1.3275rem',
    fontFamily: FONTS.Helvetica.medium,
  },
  subtitle3: {
    fontSize: '1rem',
    lineHeight: '1.1875rem',
    fontFamily: FONTS.Helvetica.medium,
  },
  subtitle4: {
    fontSize: '.875rem',
    lineHeight: '1rem',
    fontFamily: FONTS.Helvetica.medium,
  },

  body1: {
    fontSize: '1rem',
    lineHeight: '1.1875rem',
    fontFamily: FONTS.Helvetica.regular,
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    fontFamily: FONTS.Helvetica.regular,
  },
};
