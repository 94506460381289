import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import {
  ErrorResponseType,
  LoginParamsType,
  LoginFormFieldsType,
} from 'common/types';
import { loginSchema } from 'helpers/validationSchemas';
import { FormInputController, PhoneInputController } from 'components/Inputs';
import { CustomButton } from 'components';
import { FormTemplate } from 'components/templates';

const LoginForm = ({
  authError,
  isLoading,
  loginUser,
}: {
  authError: ErrorResponseType | null;
  isLoading: boolean;
  loginUser: (params: LoginParamsType) => void;
}) => {
  const { t } = useTranslation();
  const defaultValues = { phoneNumber: '', password: '' };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(loginSchema),
  });

  const { control, handleSubmit } = methods;

  const onSubmit: SubmitHandler<LoginFormFieldsType> = (
    data: LoginFormFieldsType,
    e: any,
  ) => {
    if (data) {
      loginUser(data);
    }
    e.target.reset();
  };

  return (
    <FormTemplate
      title={t('Sign in')}
      onSubmit={(e) => handleSubmit(onSubmit)(e)}
      errorMessage={authError?.message}
    >
      <PhoneInputController
        control={control}
        name='phoneNumber'
        placeholder={t('Phone number')}
      />
      <FormInputController
        control={control}
        name='password'
        type='password'
        placeholder={t('Password')}
      />
      <CustomButton
        fullWidth
        type='submit'
        isLoading={isLoading}
        style={{ margin: '15px auto 0' }}
      >
        {t('Login')}
      </CustomButton>
    </FormTemplate>
  );
};

export default LoginForm;
