import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'styled-components';
import dayjs from 'dayjs';

import { getLocations } from 'common/store/selectors';
import { LocationType, SearchOfferParamsType } from 'common/types';
import { useAppDispatch } from 'common/store';
import { locationActions, modalActions } from 'common/store/slices';
import { CustomButton } from 'components';
import { CustomInput, CustomDatePicker } from 'components/Inputs';
import {
  ChooseLocation,
  PassengersCounterContent,
} from 'components/ModalContents';
import { LocationIcon, PassengerIcon } from 'assets/svg';
import { WHITE } from 'styles/colors';
import {
  LocationFieldsWrapper,
  SearchFormTitle,
  SearchFormWrapper,
} from './styles';

export const modalStyle: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  backgroundColor: `${WHITE}`,
};

const SearchOfferForm = ({
  handleFormSubmit,
  isLoading,
  title,
}: {
  handleFormSubmit: (params: SearchOfferParamsType) => void;
  isLoading: boolean;
  title?: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const locations = useSelector(getLocations);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [pickupLocation, setPickupLocation] = useState<LocationType>();
  const [dropoffLocation, setDropoffLocation] = useState<LocationType>();
  const [requestedSeats, setRequestedSeats] = useState<number>(1);
  const [pickupTime, setPickupTime] = useState<string>(
    new Date().toISOString(),
  );

  const maxSelectableDate = dayjs(new Date()).add(6, 'day');

  const filteredPickup = locations?.filter(
    (region) => region.id != dropoffLocation?.id,
  );

  const filteredDropoff = locations?.filter(
    (region) => region.id != pickupLocation?.id,
  );

  const handlePickupLocation = () => {
    dispatch(
      modalActions.showModal({
        id: 'CHOOSE_LOCATION_MODAL',
        modalContent: (
          <ChooseLocation
            options={filteredPickup}
            location={pickupLocation}
            setLocation={setPickupLocation}
            closeModal={() =>
              dispatch(modalActions.hideModal('CHOOSE_LOCATION_MODAL'))
            }
          />
        ),
        boxStyle: modalStyle,
      }),
    );
  };

  const handleDropoffLocation = () => {
    dispatch(
      modalActions.showModal({
        id: 'CHOOSE_LOCATION_MODAL',
        modalContent: (
          <ChooseLocation
            options={filteredDropoff}
            location={dropoffLocation}
            setLocation={setDropoffLocation}
            closeModal={() =>
              dispatch(modalActions.hideModal('CHOOSE_LOCATION_MODAL'))
            }
          />
        ),
        boxStyle: modalStyle,
      }),
    );
  };

  const handleRequestedSeats = () => {
    dispatch(
      modalActions.showModal({
        id: 'PASSENGERS_COUNT_MODAL',
        modalContent: (
          <PassengersCounterContent
            value={requestedSeats}
            setValue={setRequestedSeats}
            closeModal={() =>
              dispatch(modalActions.hideModal('PASSENGERS_COUNT_MODAL'))
            }
          />
        ),
        boxStyle: modalStyle,
      }),
    );
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!pickupLocation || !dropoffLocation || !pickupTime) return;

    handleFormSubmit({
      pickupLocationId: String(pickupLocation.id),
      dropoffLocationId: String(dropoffLocation.id),
      requestedSeats: String(requestedSeats),
      pickupTime,
    });

    // add search to history
    dispatch(
      locationActions.addSearchHistory({ pickupLocation, dropoffLocation }),
    );
  };

  useEffect(() => {
    if (pickupLocation && dropoffLocation && pickupTime) setFormValid(true);
  }, [pickupLocation, dropoffLocation, pickupTime]);

  return (
    <SearchFormWrapper noValidate onSubmit={onSubmit} role='search'>
      {title && <SearchFormTitle>{title}</SearchFormTitle>}

      <LocationFieldsWrapper>
        <CustomInput
          placeholder={t('Pickup location')}
          onClick={handlePickupLocation}
          value={pickupLocation?.district}
          Icon={LocationIcon}
        />
        <CustomInput
          placeholder={t('Dropoff location')}
          onClick={handleDropoffLocation}
          value={dropoffLocation?.district}
          Icon={LocationIcon}
        />
      </LocationFieldsWrapper>

      <span style={{ display: 'flex', gap: 10 }}>
        <CustomDatePicker
          date={pickupTime}
          setDate={setPickupTime}
          dateProps={{
            disablePast: true,
            maxDate: maxSelectableDate,
          }}
        />
        <CustomInput
          onClick={handleRequestedSeats}
          value={requestedSeats}
          Icon={PassengerIcon}
        />
      </span>

      <CustomButton type='submit' isLoading={isLoading} disabled={!formValid}>
        {t('Search')}
      </CustomButton>
    </SearchFormWrapper>
  );
};

export default SearchOfferForm;
