import styled from 'styled-components';
import { PRIMARY_BLUE_300 } from 'styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  height: 100vh;
  padding: 50px 0;

  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

export const CounterButton = styled.button`
  width: 60px;
  height: 60px;
  font-size: 1.75rem;
  color: ${PRIMARY_BLUE_300};
  border: 2px solid ${PRIMARY_BLUE_300};
  border-radius: 50%;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: auto;
  }
`;

export const CounterCount = styled.span`
  flex-grow: 1;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
