import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Seo } from 'components';
import { AnimatedWrapper } from 'styles/AnimatedRoutes';

const AccountPage = () => {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), 0);
  }, [pathname]);

  return (
    <>
      <Seo title='Profil | Taksifon' description="Profil ma'lumotlari" />

      <AnimatedWrapper key={visible.toString()}>
        {visible ? <Outlet /> : <div />}
      </AnimatedWrapper>
    </>
  );
};

export default AccountPage;
