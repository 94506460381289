import { memo } from 'react';
import { Controller } from 'react-hook-form';
import { InputBaseComponentProps } from '@mui/material';
import { ErrorMessage, InputWrapper, Label, StyledTextField } from './styles';

interface Props {
  control: any;
  name: string;
  type?: string;
  label?: string;
  autoComplete?: string;
  placeholder?: string;
  inputProps?: InputBaseComponentProps;
}

const FormInputController = ({
  control,
  name,
  type = 'text',
  label,
  autoComplete,
  placeholder,
  inputProps,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputWrapper>
            <Label>{label}</Label>
            <StyledTextField
              {...field}
              autoComplete={autoComplete}
              inputProps={inputProps}
              error={!!error}
              placeholder={placeholder}
              type={type}
            />
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </InputWrapper>
        );
      }}
    />
  );
};

export default memo(FormInputController);
