import { Typography, Avatar } from 'components';
import { GREY_300 } from 'styles/colors';
import { CardWrapper, NameTitle } from './styles';

interface Props {
  displayName: string;
  description?: string;
  avatar?: string;
}

const AccountDetailsCard = ({ avatar, description, displayName }: Props) => {
  return (
    <CardWrapper>
      <div>
        <NameTitle>{displayName}</NameTitle>
        {description && (
          <Typography type='title3' style={{ color: GREY_300 }}>
            {description}
          </Typography>
        )}
      </div>

      <Avatar src={avatar} size='80px' />
    </CardWrapper>
  );
};

export default AccountDetailsCard;
