import ReactGA from 'react-ga4';

const useGoogleAnalytics = () => {
  const addPageView = (page: string) => {
    ReactGA.send({ hitType: 'pageview', page });
  };

  const trackEvent = (category: string, action: string, label: string) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };

  return { addPageView, trackEvent };
};

export default useGoogleAnalytics;
