import styled from 'styled-components';
import { Typography } from 'components';
import { MAX_CONTENT_WIDTH } from 'styles/common';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';
import { PRIMARY_BLUE_300, WHITE } from 'styles/colors';

export const FooterWrapper = styled.footer`
  background-color: ${WHITE};
  box-shadow: rgba(19, 19, 20, 0.05) 0px 6px 20px 0px;
  padding: 0 20px;
`;

export const FooterContentWrapper = styled.div`
  height: 48px;
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RightBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const LogoTitle = styled.p`
  text-transform: uppercase;
  font-family: ${FONTS.Helvetica.bold};
  letter-spacing: 1px;
  font-size: 1rem;
  color: ${COLORS.black};
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 0.875rem;
  }
`;

export const LinkTitle = styled(Typography)`
  font-size: 0.75rem;
  color: ${COLORS.black}90;
  text-transform: uppercase;
  transition: 0.4s;
  cursor: pointer;

  &:hover {
    color: ${PRIMARY_BLUE_300};
  }
`;
