import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { ENDPOINTS, axiosInstance } from '../../axios';
import { ErrorResponseType } from '../../types';

export const getLocationsThunk: AsyncThunkPayloadCreator<
  any,
  undefined,
  { rejectValue: ErrorResponseType }
> = async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.getAllLocations);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
