import axios, { InternalAxiosRequestConfig } from 'axios';
import { includes } from 'lodash';
import Cookies from 'js-cookie';

import { refreshTokens } from './api';
import { ENDPOINTS } from './endpoints';
import { COOKIES_KEYS } from 'appConstants';
import { getApiUrl } from '../../services';

export const axiosInstance = axios.create({ baseURL: getApiUrl() });

const endpointsWithoutToken = [ENDPOINTS.login, ENDPOINTS.register];

axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  if (config.headers && !includes(endpointsWithoutToken, config.url)) {
    const accessToken = Cookies.get(COOKIES_KEYS.accessToken);

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    }
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config } = error;

    if (error.code === 'ERR_NETWORK' || error.message === 'Network Error') {
      // window.location.replace('/');
    }

    if (response && !includes(endpointsWithoutToken, config.url)) {
      const { status, data } = response;

      // if refresh token is expired
      if (config.url === ENDPOINTS.refreshToken && status === 401) {
        Cookies.remove(COOKIES_KEYS.refreshToken);
        window.location.reload();
      }

      if (status === 401 && !config.retry && data?.message === 'Unauthorized') {
        config.retry = true;

        try {
          await refreshTokens();
          return await axiosInstance(config);
        } catch (err) {
          return Promise.reject(err);
        }
      }
    }

    return Promise.reject(error);
  },
);
