import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginUserThunk, registerUserThunk } from './authThunk';
import { getLocationsThunk } from './locationThunk';
import {
  createOfferThunk,
  getOfferDetailsThunk,
  searchOffersThunk,
} from './offerThunk';
import { createRequestThunk } from './requestThunk';
import {
  addVehicleThunk,
  getMeThunk,
  getMyOfferDetailsThunk,
  getMyOffersThunk,
  getUserVehicleThunk,
  updateUserThunk,
} from './accountThunk';
import {
  cancelBookingThunk,
  confirmBookingThunk,
  makeBookingThunk,
  getBookingByIdThunk,
} from './bookingThunk';
import { getUserByIdThunk } from './userThunk';

export const AsyncThunks = {
  registerUser: createAsyncThunk('registerUser', registerUserThunk),
  loginUser: createAsyncThunk('loginUser', loginUserThunk),
  getUserById: createAsyncThunk('getUserById', getUserByIdThunk),
  getMe: createAsyncThunk('getMe', getMeThunk),
  updateUser: createAsyncThunk('updateUser', updateUserThunk),
  addVehicle: createAsyncThunk('addVehicle', addVehicleThunk),
  getUserVehicle: createAsyncThunk('getUserVehicle', getUserVehicleThunk),
  getMyOffers: createAsyncThunk('getMyOffers', getMyOffersThunk),
  getMyOfferDetails: createAsyncThunk(
    'getMyOfferDetails',
    getMyOfferDetailsThunk,
  ),

  createOffer: createAsyncThunk('createOffer', createOfferThunk),
  searchOffers: createAsyncThunk('searchOffers', searchOffersThunk),
  getOfferDetails: createAsyncThunk('getOfferDetails', getOfferDetailsThunk),

  createRequest: createAsyncThunk('createRequest', createRequestThunk),

  getLocations: createAsyncThunk('getLocations', getLocationsThunk),

  makeBooking: createAsyncThunk('makeBooking', makeBookingThunk),
  getBookingById: createAsyncThunk('getBookingById', getBookingByIdThunk),
  confirmBooking: createAsyncThunk('confirmBooking', confirmBookingThunk),
  cancelBooking: createAsyncThunk('cancelBooking', cancelBookingThunk),
};
