import { memo } from 'react';
import { Controller } from 'react-hook-form';
import { MobileDateTimePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import CustomLocalizationProvider from './CustomLocalizationProvider';
import {
  ErrorMessage,
  InputWrapper,
  StyledMobileDateTimePicker,
  Label,
} from './styles';

const BasicDateTimePicker = ({
  control,
  name,
  label,
  dateProps,
}: {
  control: any;
  name: string;
  label?: string;
  dateProps?: MobileDateTimePickerProps<any>;
}) => {
  return (
    <CustomLocalizationProvider>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <InputWrapper>
              <Label>{label}</Label>
              <StyledMobileDateTimePicker
                {...field}
                {...dateProps}
                ampm={false}
                onChange={(newValue: any) => {
                  if (newValue) {
                    field.onChange(dayjs(newValue).toISOString());
                  } else {
                    field.onChange('');
                  }
                }}
              />
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </InputWrapper>
          );
        }}
      />
    </CustomLocalizationProvider>
  );
};

export default memo(BasicDateTimePicker);
