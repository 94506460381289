import { useTranslation } from 'react-i18next';

import { LANGUAGES, LOCAL_STORAGE_KEYS } from 'appConstants';
import { LanguagesContainer, LanguageItem } from './styles';
import { Cross } from 'pages/LoginPage/LoginPage.styles';

const ChooseLanguageContent = ({ closeModal }: { closeModal: () => void }) => {
  const { i18n } = useTranslation();
  const activeLanguage = i18n.language;

  const handleChooseLanguage = async (lang: string) => {
    await i18n.changeLanguage(lang);
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.language, lang);
    window.location.reload();
    closeModal();
  };

  return (
    <LanguagesContainer>
      <Cross onClick={closeModal} />

      {LANGUAGES.map((language, index) => (
        <LanguageItem
          key={index}
          active={language.lang === activeLanguage}
          onClick={() => handleChooseLanguage(language.lang)}
        >
          {language.title}
        </LanguageItem>
      ))}
    </LanguagesContainer>
  );
};

export default ChooseLanguageContent;
