import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getLocations } from 'common/store/selectors';

const useDistrictOptions = ({
  dropoffLocationId,
  pickupLocationId,
}: {
  dropoffLocationId: string;
  pickupLocationId: string;
}) => {
  const locations = useSelector(getLocations);

  const locationOptions = useMemo(() => {
    if (!locations) return [];

    return locations.map((location) => ({
      id: location.id,
      name: `${location.district}, ${location.region}`,
    }));
  }, [locations]);

  const filteredPickup = useMemo(() => {
    return locationOptions.filter(
      (location) => location.id !== dropoffLocationId,
    );
  }, [dropoffLocationId]);

  const filteredDropoff = useMemo(() => {
    return locationOptions.filter(
      (location) => location.id !== pickupLocationId,
    );
  }, [pickupLocationId]);

  return { filteredDropoff, filteredPickup };
};

export default useDistrictOptions;
