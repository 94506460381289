import { UserRole } from 'common/types';
import dayjs from 'dayjs';

export const getAgeFromBirthdate = (dateOfBirth: string) => {
  const birthDate = dayjs(dateOfBirth);
  const currentDate = dayjs();
  const age = currentDate.diff(birthDate, 'year');
  return age;
};

export const getFullName = ({
  firstName,
  lastName,
}: {
  firstName?: string;
  lastName?: string;
}) => {
  return `${firstName} ${lastName}`;
};

export const getUserRole = (role?: UserRole) => {
  const roles = {
    [UserRole.PASSENGER]: 'Passenger',
    [UserRole.DRIVER]: 'Driver',
    [UserRole.ADMIN]: 'Admin',
  };

  return roles[role || UserRole.PASSENGER];
};
