import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/uz-latn';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import i18n from 'i18n/i18n';
import { LanguageType } from 'common/types';

dayjs.extend(weekday);

const getDayjsLocale = (lang: LanguageType) => {
  switch (lang) {
    case LanguageType.UZ:
      dayjs.locale('uz-latn');
      break;
    case LanguageType.EN:
      dayjs.locale('en');
      break;
    case LanguageType.RU:
      dayjs.locale('ru');
      break;
    default:
      dayjs.locale('en');
      break;
  }
};

getDayjsLocale(i18n.language as LanguageType);

export const getNextSevenDays = () => {
  const now = dayjs();
  const nextSevenDays = Array.from({ length: 7 }, (_, i) => now.add(i, 'day'));
  return nextSevenDays.map((day) => {
    const dayOfWeek = day.format('ddd');
    const formattedDate = day.format('DD MMM');
    const data = day.format('YYYY-MM-DD');
    return { label: `${dayOfWeek}, ${formattedDate}`, value: data };
  });
};

export const formatDate = (date: any) => {
  return dayjs(date).format('YYYY-MM-DD');
};

// Today, Tomorrow or November 4
export const formatDateWithWords = (dateString: any) => {
  const today = dayjs();
  const tomorrow = today.add(1, 'day');
  const date = dayjs(dateString, { format: 'MM/DD/YYYY' });

  if (date.isSame(today, 'day')) {
    return i18n.t('Today');
  } else if (date.isSame(tomorrow, 'day')) {
    return i18n.t('Tomorrow');
  } else {
    const formattedWeekday = date.format('ddd');
    const formattedDate = date.format('MMMM D');
    return `${formattedWeekday}, ${formattedDate}`;
  }
};

export const formatDateWithDayAndWeekday = (dateString: any) => {
  const date = dayjs(dateString, { format: 'ddd, DD MMMM' });

  const formattedWeekday = date.format('ddd');
  const formattedDate = date.format('MMMM D');

  return `${formattedWeekday}, ${formattedDate}`;
};

export const paramsToObject = (entries: any) => {
  const result: any = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

export const formatPrice = (price: number) => {
  const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };
  const formattedPrice = price.toLocaleString('uz-UZ', options);
  return `${formattedPrice} so'm`;
};

export const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  const formattedTime = date.toLocaleTimeString([], {
    timeZone: 'Asia/Tashkent',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  return formattedTime;
};

export const getAgeFromBirthdate = (dateOfBirth: string) => {
  const birthDate = dayjs(dateOfBirth);
  const currentDate = dayjs();
  const age = currentDate.diff(birthDate, 'year');
  return age;
};
