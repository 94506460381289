import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { ENDPOINTS, axiosInstance } from 'common/axios';
import { CreateRequestParamsType, ErrorResponseType } from 'common/types';

export const createRequestThunk: AsyncThunkPayloadCreator<
  any,
  CreateRequestParamsType,
  { rejectValue: ErrorResponseType }
> = async (requestParams, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(
      ENDPOINTS.createRequest,
      requestParams,
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
