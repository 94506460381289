import styled, { css } from 'styled-components';
import Typography from 'components/Typography/Typography';
import { GREY_100, GREY_300, PRIMARY_BLUE_300, WHITE } from 'styles/colors';

export const ItemContainer = styled.div<{
  isClickable: boolean;
}>`
  padding: 16px 20px;
  background-color: ${WHITE};
  display: flex;
  align-items: center;
  flex-grow: 1;
  transition: 0.3s;

  ${({ isClickable }) =>
    isClickable &&
    css`
      color: ${PRIMARY_BLUE_300};
      cursor: pointer;

      span {
        color: ${PRIMARY_BLUE_300};
      }

      &:hover {
        background-color: ${GREY_100}50;
      }
    `}
`;

export const ItemLeftContainer = styled.span`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const LeftTextWrapper = styled.span`
  flex: 1 1 0%;
`;

export const IconWrapper = styled.span<{
  iconColor?: string;
}>`
  & svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    & path {
      stroke: ${({ iconColor }) => iconColor || PRIMARY_BLUE_300};
    }
  }
`;

export const IconRightWrapper = styled.span`
  & svg {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    flex-shrink: 0;
    & path {
      stroke: ${GREY_300};
    }
  }
`;

export const LeftText = styled(Typography).attrs({
  tag: 'span',
  type: 'subtitle2',
  color: 'secondary',
})`
  text-align: start;
  font-size: 1rem;
`;
