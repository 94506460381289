import styled from 'styled-components';
import { BREAKPOINTS, FONTS } from 'styles';
import { GREY_300, GREY_400, WHITE } from 'styles/colors';
import { MAX_CONTENT_WIDTH } from 'styles/common';

export const PageWrapper = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || WHITE};
  display: flex;
  flex-direction: column;

  flex: 1 1 0%;
`;

export const PageContentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 60px 30px;
  max-width: ${MAX_CONTENT_WIDTH}px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 30px 15px;
  }
`;

export const PageTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 26px;

  & svg {
    width: 34px;
    height: 34px;
    margin-right: 10px;
    cursor: pointer;

    & path {
      fill: ${GREY_300};
    }
  }
`;

export const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-family: ${FONTS.Helvetica.bold};
  color: ${GREY_400};
  /* text-transform: uppercase; */
  text-align: center;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    text-align: start;
    font-size: 1.375rem;
  }
`;
