import styled from 'styled-components';
import { WHITE } from 'styles/colors';
import { FONTS } from 'styles';

export const AddVehicleContainer = styled.div`
  min-width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const LanguagesContainer = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LanguageItem = styled.button<{
  active: boolean;
}>`
  width: 100%;
  padding: 12px 14px;
  border-radius: 4px;
  font-size: 1.125rem;
  font-family: ${FONTS.Helvetica.regular};
  background-color: ${({ active }) => (active ? '#d9e6eb5e' : WHITE)};
  transition: 0.4s;

  &:hover {
    background-color: '#d9e6eb5e';
  }
`;

export const SuccessContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  max-width: 420px;
  gap: 20px;
`;
