import styled, { css } from 'styled-components';

export const LoaderWrapper = styled.div<{
  fullscreen?: boolean;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0.5);
    `}
`;
