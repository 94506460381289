import styled from 'styled-components';
import {
  AccountIcon,
  ChevronDownIcon,
  HamburgerIcon,
  HeaderLogo,
} from 'assets/svg';
import { CustomNavLink } from 'components';
import { MAX_CONTENT_WIDTH } from 'styles/common';
import { BREAKPOINTS, FONTS } from 'styles';
import { GREY_500, PRIMARY_BLUE_300, WHITE } from 'styles/colors';

export const HEADER_MOBILE_HEIGHT = 56;

export const HeaderWrapper = styled.header`
  padding: 0 20px;
  background-color: ${WHITE};
`;

export const HeaderContentWrapper = styled.div`
  height: 62px;
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.s}px) {
    height: ${HEADER_MOBILE_HEIGHT}px;
  }
`;

export const RightBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled(HeaderLogo)`
  width: 36px;
  height: 36px;
  margin-right: 6px;
  & path {
    fill: ${PRIMARY_BLUE_300};
  }
  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 28px;
    height: 28px;
  }
`;

export const LogoTitle = styled.p`
  text-transform: uppercase;
  font-family: ${FONTS.Helvetica.bold};
  letter-spacing: 1px;
  font-size: 1.125rem;
  color: ${GREY_500};
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 1rem;
  }
`;

export const HamburgerIconButton = styled(HamburgerIcon)`
  cursor: pointer;
  display: none;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: block;
  }
`;

export const HamburgerMenuContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
`;

export const HamburgerMenuItem = styled.button`
  all: unset;
  font-size: 0.875rem;
  color: ${GREY_500};
  opacity: 0.9;
  font-family: ${FONTS.Helvetica.medium};
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.4s;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const AuthButton = styled.button`
  color: ${WHITE};
  background-color: ${PRIMARY_BLUE_300};
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 0.875rem;
  font-family: ${FONTS.Helvetica.medium};
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.4s;
  margin-left: 18px;

  &:hover {
    opacity: 0.6;
  }
`;

export const ProfileIcon = styled(AccountIcon)`
  width: 40px;
  height: 40px;
  margin-left: 12px;
  cursor: pointer;
  path {
    stroke: ${PRIMARY_BLUE_300};
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  margin-right: 30px;
`;

export const LinkTitle = styled(CustomNavLink)`
  font-size: 0.875rem;
  font-family: ${FONTS.Helvetica.medium};
  text-transform: uppercase;
  color: ${GREY_500};
  transition: 0.4s;

  &.active {
    color: ${PRIMARY_BLUE_300};
  }

  &:hover {
    color: ${PRIMARY_BLUE_300};
  }
`;

export const LanguageButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${FONTS.Helvetica.bold};
  color: ${GREY_500};
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 4px 8px;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export const LanguageChevronDownIcon = styled(ChevronDownIcon)`
  width: 16px;
  height: 16px;
  & path {
    stroke: ${GREY_500};
  }
`;
