import Cookies from 'js-cookie';

import { axiosInstance } from './axiosInstance';
import { ENDPOINTS } from './endpoints';
import { COOKIES_KEYS } from 'appConstants';
import { updateLocalTokens } from 'services';

const handleError = (e: any) => {
  throw e;
};

export const refreshTokens = async () => {
  const refreshToken = Cookies.get(COOKIES_KEYS.refreshToken);

  if (refreshToken) {
    try {
      const { data } = await axiosInstance.get(ENDPOINTS.refreshToken, {
        headers: { Authorization: `Bearer ${refreshToken}` },
      });

      updateLocalTokens(data);

      return data;
    } catch (error) {
      handleError(error);
    }
  }
  return null;
};
