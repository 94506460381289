import { RootState } from './rootReducer';

export const getIsLoggedIn = (state: RootState) =>
  state.accountReducer.isLoggedIn;
export const getAccountError = (state: RootState) => state.accountReducer.error;
export const getAccountLoader = (state: RootState) =>
  state.accountReducer.pending;
export const getAccountDetails = (state: RootState) =>
  state.accountReducer.result;
export const getAccountId = (state: RootState) => state.accountReducer.userId;
export const getUserVehicle = (state: RootState) =>
  state.accountReducer.result?.vehicle;
export const getAccountOffers = (state: RootState) =>
  state.accountReducer.myOffers;
export const getAccountOfferDetails = (state: RootState) =>
  state.accountReducer.myOfferDetails;
export const getMyOffersAndBookings = (state: RootState) =>
  state.accountReducer.myOffers;
export const getRegistrationResult = (state: RootState) =>
  state.accountReducer.registrationResult;

export const getModals = (state: RootState) => state.modalReducer.modals;

export const getLocations = (state: RootState) => state.locationReducer.result;
export const getOfferSearchHistoryList = (state: RootState) =>
  state.locationReducer.searchHistoryList;

export const getOfferSearchList = (state: RootState) =>
  state.offerSearchReducer.result;
export const getOfferSearchListLoader = (state: RootState) =>
  state.offerSearchReducer.pending;
export const getOfferSearchListError = (state: RootState) =>
  state.offerSearchReducer.error;

export const getOffer = (state: RootState) => state.offerReducer.result;
export const getOfferLoader = (state: RootState) => state.offerReducer.pending;
export const getOfferError = (state: RootState) => state.offerReducer.error;

export const getRequestList = (state: RootState) =>
  state.requestReducer.requestList;
export const getRequestLoader = (state: RootState) =>
  state.requestReducer.pending;
export const getRequestError = (state: RootState) => state.requestReducer.error;

export const getBookingError = (state: RootState) => state.bookingReducer.error;
export const getBookingLoader = (state: RootState) =>
  state.bookingReducer.pending;

export const getUserDetails = (state: RootState) => state.userReducer.result;
export const getUserLoader = (state: RootState) => state.userReducer.pending;
export const getUserError = (state: RootState) => state.userReducer.error;
