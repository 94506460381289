import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import { getUserDetails, getUserLoader } from 'common/store/selectors';
import { PageTemplate, AccountDetailsCard } from 'components';
import { getUserRole, getFullName } from 'helpers/utils';

const UserDetailsPage = () => {
  const { id: userId } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const user = useSelector(getUserDetails);
  const isLoading = useSelector(getUserLoader);

  if (!userId) return null;

  const fetchUserDetails = async () => {
    await dispatch(AsyncThunks.getUserById(userId));
  };

  useEffect(() => {
    fetchUserDetails();
  }, [userId]);

  return (
    <PageTemplate title=' ' isLoading={isLoading} showBackButton>
      <AccountDetailsCard
        avatar={user?.photo || ''}
        displayName={getFullName({
          firstName: user?.firstName,
          lastName: user?.lastName,
        })}
        description={t(getUserRole(user?.role))}
      />
    </PageTemplate>
  );
};

export default UserDetailsPage;
