import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { modalActions } from 'common/store/slices';
import { getModals } from 'common/store/selectors';
import { useAppDispatch } from 'common/store';
import { ModalBackground, ModalWrapper } from './styled';

const CustomModal = () => {
  const dispatch = useAppDispatch();
  const modals = useSelector(getModals);

  const hideModal = () => {
    dispatch(modalActions.hideAllModals());
  };

  useEffect(() => {
    window.addEventListener('popstate', hideModal);
    return () => {
      window.removeEventListener('popstate', hideModal);
    };
  }, []);

  return (
    <div>
      {modals.map(({ isOpen, modal }) => {
        const { id, modalContent, boxStyle, actionOnClose } = modal;
        if (!isOpen) return null;
        const closeModal = () => {
          dispatch(modalActions.hideModal(id));
          actionOnClose && actionOnClose();
        };

        return (
          <ModalBackground key={id} onClick={closeModal}>
            <ModalWrapper style={boxStyle} onClick={(e) => e.stopPropagation()}>
              {modalContent || null}
            </ModalWrapper>
          </ModalBackground>
        );
      })}
    </div>
  );
};

export default CustomModal;
