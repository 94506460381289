import styled from 'styled-components';
import { GREY_200 } from 'styles/colors';

export const CardWrapper = styled.li`
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid ${GREY_200};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;
