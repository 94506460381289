import styled from 'styled-components';

export const AvatarWrapper = styled.div<{
  size?: string;
}>`
  min-width: ${({ size }) => size || '40px'};
  height: ${({ size }) => size || '40px'};
  border-radius: 50%;
  overflow: hidden;

  & svg {
    width: ${({ size }) => size || '40px'};
    height: ${({ size }) => size || '40px'};
  }
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
