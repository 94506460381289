import { Offer } from 'common/types';
import { formatPrice, formatTime } from 'helpers/date';
import { DriverDetailsCard, RouteSummary } from 'components';
import { OfferCardWrapper, OfferPrice } from './styles';

interface Props {
  offer: Offer;
  onClick?: () => void;
}

const SearchOfferItem = ({ offer, onClick }: Props) => {
  return (
    <OfferCardWrapper onClick={onClick}>
      <OfferPrice>{formatPrice(offer.price.amount)}</OfferPrice>

      <div style={{ position: 'relative', left: -10 }}>
        <RouteSummary
          pickupLabel={offer.departure.district}
          dropoffLabel={offer.arrival.district}
          formattedDepartureTime={formatTime(offer.departureTime)}
        />
      </div>

      {offer.driver && (
        <div style={{ paddingTop: 10 }}>
          <DriverDetailsCard
            avatar={offer.driver.photo || ''}
            displayName={offer.driver.firstName}
          />
        </div>
      )}
    </OfferCardWrapper>
  );
};

export default SearchOfferItem;
