import Cookies from 'js-cookie';
import { COOKIES_KEYS, TOKEN_EXPIRATION } from 'appConstants';

export const updateLocalTokens = (tokens: {
  accessToken: string;
  refreshToken: string;
}) => {
  const { accessToken, refreshToken } = tokens;

  Cookies.set(COOKIES_KEYS.accessToken, accessToken, {
    expires: TOKEN_EXPIRATION.accessToken,
  });
  Cookies.set(COOKIES_KEYS.refreshToken, refreshToken, {
    expires: TOKEN_EXPIRATION.refreshToken,
  });
};
