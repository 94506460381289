import { memo, ReactNode } from 'react';

import { Loader, Typography } from 'components';
import { ButtonWrapper } from './styles';

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

interface CustomButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  fullWidth?: boolean;
  inverted?: boolean;
  isLoading?: boolean;
  size?: ButtonSize;
}

const CustomButton = ({
  children,
  fullWidth,
  inverted,
  isLoading,
  size = ButtonSize.Medium,
  ...props
}: CustomButtonProps) => {
  return (
    <ButtonWrapper
      fullWidth={fullWidth}
      inverted={inverted}
      size={size}
      {...props}
    >
      {isLoading ? <Loader /> : <Typography>{children}</Typography>}
    </ButtonWrapper>
  );
};

export default memo(CustomButton);
