import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import { getRequestError, getRequestLoader } from 'common/store/selectors';
import { CreateRequestFormFieldsType } from 'common/types';
import { createRequestSchema } from 'helpers/validationSchemas';
import { useModals, useDistrictOptions } from 'hooks';
import { CustomButton, FormTemplate, PageTemplate, Seo } from 'components';
import {
  BasicDateTimePicker,
  LocationAutocomplete,
  CounterInputController,
} from 'components/Inputs';

const CreateRequestPage = () => {
  const dispatch = useAppDispatch();
  const error = useSelector(getRequestError);
  const isLoading = useSelector(getRequestLoader);
  const { showCreateRequestSuccessModal } = useModals();
  const { t } = useTranslation();

  const defaultValues: CreateRequestFormFieldsType = {
    fromPlaceId: '',
    toPlaceId: '',
    departureTime: '',
    numberOfPassengers: 1,
  };

  const methods = useForm({
    resolver: yupResolver(createRequestSchema),
    defaultValues,
  });

  const { control, handleSubmit, watch } = methods;

  const { fromPlaceId, toPlaceId } = watch();

  const { filteredPickup, filteredDropoff } = useDistrictOptions({
    pickupLocationId: fromPlaceId,
    dropoffLocationId: toPlaceId,
  });

  const maxSelectableDate = dayjs(new Date()).add(6, 'day');

  const onSubmit: SubmitHandler<CreateRequestFormFieldsType> = async (
    data: CreateRequestFormFieldsType,
    e: any,
  ) => {
    if (data) {
      const response = await dispatch(AsyncThunks.createRequest(data));
      if (!response.payload?.error) {
        showCreateRequestSuccessModal();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        methods.reset();
      }
    }
    e.target.reset();
  };

  return (
    <PageTemplate title={t('Need a taxi')} showBackButton>
      <Seo
        title="So'rov yaratish | Taksifon"
        description="Siz yo'lovchimisiz? Unda haydovchilar uchun so'rov qoldiring. Biz bilan hammasi tez, qulay va oson!"
      />

      <FormTemplate
        onSubmit={(e) => handleSubmit(onSubmit)(e)}
        errorMessage={error?.message}
      >
        <LocationAutocomplete
          id='location-from'
          name='fromPlaceId'
          label={t('Pickup location')}
          control={control}
          options={filteredPickup}
        />
        <LocationAutocomplete
          id='location-to'
          name='toPlaceId'
          label={t('Dropoff location')}
          control={control}
          options={filteredDropoff}
        />
        <BasicDateTimePicker
          control={control}
          name='departureTime'
          label={t('Pickup time')}
          dateProps={{
            disablePast: true,
            maxDate: maxSelectableDate,
          }}
        />
        <CounterInputController
          control={control}
          name='numberOfPassengers'
          label={t('Number of passengers')}
        />
        <CustomButton
          type='submit'
          isLoading={isLoading}
          style={{ marginTop: 10 }}
        >
          {t('Confirm')}
        </CustomButton>
      </FormTemplate>
    </PageTemplate>
  );
};

export default CreateRequestPage;
