import { memo } from 'react';
import { Controller } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import { ErrorMessage, InputWrapper, Label, StyledTextField } from './styles';
import { GREY_400 } from 'styles/colors';

const COUNTRY_CODE = '+998';
const MAX_PHONE_LENGTH = 9;

interface Props {
  control: any;
  name: string;
  label?: string;
  autoComplete?: string;
  placeholder?: string;
}

const formatPhoneNumber = (value: string): string => {
  const cleaned = value.replace(/\D/g, '');
  if (cleaned.length < MAX_PHONE_LENGTH) return cleaned;
  const match = cleaned.match(/^(9\d{1})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
  }
  return value;
};

const PhoneInputController = ({
  control,
  name,
  label,
  autoComplete,
  placeholder,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const formattedValue = formatPhoneNumber(field.value);

        const handleChange = (e: any) => {
          const cleanedValue = e.target.value.replace(/\D/g, '');
          field.onChange(cleanedValue);
        };

        return (
          <InputWrapper>
            <Label>{label}</Label>
            <StyledTextField
              {...field}
              value={formattedValue}
              onChange={handleChange}
              autoComplete={autoComplete}
              error={!!error}
              placeholder={placeholder}
              type='tel'
              sx={{
                '& input': {
                  paddingLeft: 0,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position='start'
                    sx={{
                      '& .MuiTypography-root': {
                        color: GREY_400,
                        fontSize: '1rem',
                      },
                    }}
                  >
                    {COUNTRY_CODE}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: MAX_PHONE_LENGTH,
              }}
            />
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </InputWrapper>
        );
      }}
    />
  );
};

export default memo(PhoneInputController);
