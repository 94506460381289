import styled from 'styled-components';
import { Typography } from 'components';
import { SwitchIcon } from 'assets/svg';
import { GREY_500, RED_300, WHITE } from 'styles/colors';
import { FONTS } from 'styles';

export const AuthFormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ErrorMessage = styled(Typography)`
  font-size: 0.75rem;
  color: ${RED_300};
`;

export const SearchFormWrapper = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 100%;
  max-width: 620px;
  margin: 0 auto;
  padding: 20px 15px;
  border-radius: 20px;
  background-color: ${WHITE};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const SearchFormTitle = styled.h1`
  font-size: 1.25rem;
  font-family: ${FONTS.Helvetica.bold};
  text-transform: uppercase;
  text-align: center;
  color: ${GREY_500};
  padding-bottom: 15px;
`;

export const LocationFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const SwitchButton = styled(SwitchIcon)`
  width: 28px;
  height: 28px;
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 5;
  cursor: pointer;

  path {
    stroke: #b8ccdd;
  }
`;
