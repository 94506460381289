import React from 'react';
import {
  IconRightWrapper,
  IconWrapper,
  ItemContainer,
  ItemLeftContainer,
  LeftText,
  LeftTextWrapper,
} from './ItemHorizontal.styles';

interface Props {
  IconLeft?: JSX.Element;
  IconRight?: JSX.Element;
  iconColor?: string;
  isClickable?: boolean;
  label: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const ItemHorizontal = ({
  IconLeft,
  IconRight,
  iconColor,
  isClickable = false,
  label,
  onClick,
  style,
}: Props) => {
  return (
    <ItemContainer isClickable={isClickable} onClick={onClick} style={style}>
      <ItemLeftContainer>
        {!!IconLeft && (
          <IconWrapper iconColor={iconColor}>{IconLeft}</IconWrapper>
        )}

        <LeftTextWrapper>
          {label && <LeftText>{label}</LeftText>}
        </LeftTextWrapper>
      </ItemLeftContainer>

      {!!IconRight && <IconRightWrapper>{IconRight}</IconRightWrapper>}
    </ItemContainer>
  );
};

export default ItemHorizontal;
