export default {
  black: '#063942',
  border: '#cecccc',
};

export const BODY = '#f6f9ff';

export const PRIMARY_BLUE_100 = '#C3E9FE';
export const PRIMARY_BLUE_200 = '#77C8F5';
export const PRIMARY_BLUE_300 = '#188AEC';
export const PRIMARY_BLUE_400 = '#147CD6';
export const PRIMARY_BLUE_500 = '#075BA3';

export const GREY_100 = '#E7EBEF';
export const GREY_200 = '#B1C1C9';
export const GREY_300 = '#87A0AC';
export const GREY_400 = '#3F525B';
export const GREY_500 = '#2E3C43';

export const RED_100 = '#FF9A9A';
export const RED_200 = '#F57878';
export const RED_300 = '#E11D1D';
export const RED_400 = '#BD1111';
export const RED_500 = '#BD1111';

export const GREEN_100 = '#84FFB5';
export const GREEN_200 = '#45F38B';
export const GREEN_300 = '#1CC35F';
export const GREEN_400 = '#0E9343';
export const GREEN_500 = '#106D35';

export const WHITE = '#FFFFFF';
export const WHITE_100 = '#FBFBFB';
export const WHITE_200 = '#E7E7E7';
