import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import {
  ErrorResponseType,
  LoginParamsType,
  RegisterParamsType,
} from 'common/types';
import { ENDPOINTS, axiosInstance } from 'common/axios';
import { updateLocalTokens } from 'services';
import { AsyncThunks } from '.';

export const registerUserThunk: AsyncThunkPayloadCreator<
  any,
  RegisterParamsType,
  { rejectValue: ErrorResponseType }
> = async (registerParams, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(ENDPOINTS.register, {
      ...registerParams,
      phoneNumber: '+998' + registerParams.phoneNumber,
    });

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const loginUserThunk: AsyncThunkPayloadCreator<
  any,
  LoginParamsType,
  { rejectValue: ErrorResponseType }
> = async (loginParams, { rejectWithValue, dispatch }) => {
  try {
    const response = await axiosInstance.post(ENDPOINTS.login, {
      ...loginParams,
      phoneNumber: '+998' + loginParams.phoneNumber,
    });
    updateLocalTokens(response.data);

    if (response.data) {
      await dispatch(AsyncThunks.getMe());
    }

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
