import { ChevronRightIcon } from 'assets/svg';
import {
  SummaryWrapper,
  SummaryItem,
  LocationBlock,
  LocationText,
  LocationSecondaryText,
  RouteTime,
  RouteLineWrapper,
  RouteDotWrapper,
  RouteDot,
  RouteChevronRight,
  RouteBottomLine,
  RouteTopLine,
} from './RouteSummary.styles';

interface Props {
  pickupLabel: string;
  pickupSecondaryLabel?: string;
  dropoffLabel: string;
  dropoffSecondaryLabel?: string;
  formattedDepartureTime: string;
  onPressPickup?: () => void;
  onPressDropoff?: () => void;
}

const RouteSummary = ({
  pickupLabel,
  dropoffLabel,
  pickupSecondaryLabel,
  dropoffSecondaryLabel,
  onPressPickup,
  onPressDropoff,
  formattedDepartureTime,
}: Props) => {
  return (
    <SummaryWrapper>
      <SummaryItem isClickable={!!onPressPickup}>
        <RouteTime>{formattedDepartureTime}</RouteTime>

        <RouteLine />

        <LocationBlock>
          <LocationText>{pickupLabel}</LocationText>
          {pickupSecondaryLabel && (
            <LocationSecondaryText>
              {pickupSecondaryLabel}
            </LocationSecondaryText>
          )}
          {onPressPickup && (
            <RouteChevronRight>
              <ChevronRightIcon />
            </RouteChevronRight>
          )}
        </LocationBlock>
      </SummaryItem>

      <SummaryItem isClickable={!!onPressDropoff}>
        <RouteTime />
        <RouteLine isRotated />
        <LocationBlock>
          <LocationText>{dropoffLabel}</LocationText>
          {dropoffSecondaryLabel && (
            <LocationSecondaryText>
              {dropoffSecondaryLabel}
            </LocationSecondaryText>
          )}
          {onPressDropoff && (
            <RouteChevronRight>
              <ChevronRightIcon />
            </RouteChevronRight>
          )}
        </LocationBlock>
      </SummaryItem>
    </SummaryWrapper>
  );
};

const RouteLine = ({ isRotated }: { isRotated?: boolean }) => {
  return (
    <RouteLineWrapper aria-hidden>
      <RouteTopLine isRotated={isRotated} />
      <RouteDotWrapper>
        <RouteDot />
      </RouteDotWrapper>
      <RouteBottomLine isRotated={isRotated} />
    </RouteLineWrapper>
  );
};

export default RouteSummary;
