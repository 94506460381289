import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'router';
import { useModals, useDimensions } from 'hooks';
import { CustomNavLink } from 'components';
import { PRODUCT_NAME } from 'appConstants';
import { BREAKPOINTS } from 'styles';
import {
  AuthButton,
  HeaderWrapper,
  HeaderContentWrapper,
  RightBlockWrapper,
  LinksWrapper,
  LogoWrapper,
  LogoTitle,
  Logo,
  ProfileIcon,
  LinkTitle,
  LanguageButton,
  LanguageChevronDownIcon,
} from './styles';
import i18n from 'i18n/i18n';

const languagesIcon: { [k: string]: string } = {
  uz: "O'zb",
  ru: 'Рус',
  en: 'Eng',
};

const Header = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { t } = useTranslation();
  const { width } = useDimensions();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showChooseLanguageModal } = useModals();
  const activeLanguage = i18n.language;

  const isLoginPage = pathname.includes(ROUTES.LOGIN);

  const navigateToLogin = () => {
    navigate(ROUTES.LOGIN);
  };

  const isDesktop = width > BREAKPOINTS.xs;

  return (
    <HeaderWrapper>
      <HeaderContentWrapper>
        <CustomNavLink to={ROUTES.HOME}>
          <LogoWrapper>
            <Logo />
            <LogoTitle>{PRODUCT_NAME}</LogoTitle>
          </LogoWrapper>
        </CustomNavLink>

        <RightBlockWrapper>
          {isDesktop && (
            <LinksWrapper>
              <LinkTitle to={ROUTES.HOME}>{t('Main')}</LinkTitle>
              <LinkTitle to={ROUTES.SEARCH}>{t('Search')}</LinkTitle>
              {isLoggedIn && (
                <LinkTitle to={ROUTES.SERVICES}>{t('Services')}</LinkTitle>
              )}
              {isLoggedIn && (
                <CustomNavLink to={ROUTES.ACCOUNT}>
                  <ProfileIcon />
                </CustomNavLink>
              )}
            </LinksWrapper>
          )}
          <LanguageButton onClick={showChooseLanguageModal}>
            {languagesIcon[activeLanguage]} <LanguageChevronDownIcon />
          </LanguageButton>
          {!isLoggedIn && !isLoginPage && (
            <AuthButton onClick={navigateToLogin}>{t('Sign in')}</AuthButton>
          )}
        </RightBlockWrapper>
      </HeaderContentWrapper>
    </HeaderWrapper>
  );
};

export default Header;
