import styled, { keyframes } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'styles';
import { LoaderWrapper } from './styles';

const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const AnimatedLoader = styled.div`
  border: 3px solid ${COLORS.border}50;
  border-top: 3px solid ${COLORS.black};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spin} 1s linear infinite;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 25px;
    height: 25px;
  }
`;

const Loader = ({ fullscreen, ...props }: { fullscreen?: boolean }) => (
  <LoaderWrapper fullscreen={fullscreen} {...props}>
    <AnimatedLoader id='loader' />
  </LoaderWrapper>
);

export default Loader;
