import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import {
  ErrorResponseType,
  RegistrationFormFieldsType,
  RegisterParamsType,
  UserRole,
} from 'common/types';
import { registrationSchema } from 'helpers/validationSchemas';
import { FormInputController, PhoneInputController } from 'components/Inputs';
import { CustomButton, FormTemplate } from 'components';
import { ErrorMessage } from './styles';

const RegistrationForm = ({
  authError,
  isLoading,
  registerUser,
}: {
  authError: ErrorResponseType | null;
  isLoading: boolean;
  registerUser: (params: RegisterParamsType) => void;
}) => {
  const { t } = useTranslation();

  const defaultValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(registrationSchema),
  });

  const { control, handleSubmit } = methods;

  const onSubmit: SubmitHandler<RegistrationFormFieldsType> = (
    data: RegistrationFormFieldsType,
    e: any,
  ) => {
    if (data) {
      registerUser({
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        password: data.password,
        role: UserRole.PASSENGER,
      });
    }
    e.target.reset();
  };

  return (
    <FormTemplate
      title={t('Register')}
      onSubmit={handleSubmit(onSubmit)}
      errorMessage={authError?.message}
    >
      {authError && <ErrorMessage>{authError.message}</ErrorMessage>}
      <FormInputController
        control={control}
        name='firstName'
        placeholder={t('First name')}
      />
      <FormInputController
        control={control}
        name='lastName'
        placeholder={t('Last name')}
      />
      <PhoneInputController
        control={control}
        name='phoneNumber'
        placeholder={t('Phone number')}
      />
      <FormInputController
        control={control}
        name='password'
        type='password'
        placeholder={t('Password')}
      />
      <FormInputController
        control={control}
        name='confirmPassword'
        type='password'
        placeholder={t('Confirm password')}
      />
      <CustomButton
        fullWidth
        type='submit'
        isLoading={isLoading}
        style={{ margin: '15px auto 0' }}
      >
        {t('Save')}
      </CustomButton>
    </FormTemplate>
  );
};

export default RegistrationForm;
