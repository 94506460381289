import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import {
  getBookingError,
  getBookingLoader,
  getAccountError,
  getAccountLoader,
  getAccountOfferDetails,
} from 'common/store/selectors';
import {
  formatDateWithDayAndWeekday,
  formatPrice,
  formatTime,
} from 'helpers/date';
import {
  BookingRequestItem,
  Divider,
  DriverDetailsCard,
  PageTemplate,
  RouteSummary,
  Seo,
  Typography,
} from 'components';
import { GREY_100 } from 'styles/colors';

const MyRideDetailsPage = () => {
  const { id: offerId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const offer = useSelector(getAccountOfferDetails);
  const error = useSelector(getAccountError);
  const isLoading = useSelector(getAccountLoader);
  const bookingLoading = useSelector(getBookingLoader);
  const bookingError = useSelector(getBookingError);

  const fetchOfferDetails = useCallback(async () => {
    if (!offerId) return;
    await dispatch(AsyncThunks.getMyOfferDetails(offerId));
  }, [dispatch, offerId]);

  const handleConfirmBooking = async (bookingId: string) => {
    await dispatch(AsyncThunks.confirmBooking(bookingId));
  };

  const handleCancelBooking = async (bookingId: string) => {
    await dispatch(AsyncThunks.cancelBooking(bookingId));
  };

  useEffect(() => {
    fetchOfferDetails();
  }, []);

  return (
    <PageTemplate
      title={`Taklif raqami - #${offerId}`}
      isLoading={isLoading || bookingLoading}
      showBackButton
    >
      <Seo
        title='Mening taklifim | Taksifon'
        description="Mening taklifim haqida ma'lumotlar"
      />
      {error && <Typography color='red'>{error.message}</Typography>}
      {bookingError && (
        <Typography color='red'>{bookingError.message}</Typography>
      )}

      <Typography type='title3' tag='h1'>
        {formatDateWithDayAndWeekday(offer?.departureTime)}
      </Typography>

      <Divider />

      {offer && (
        <RouteSummary
          pickupLabel={offer.departure?.district}
          pickupSecondaryLabel={offer.departure.region}
          onPressPickup={() => {}}
          onPressDropoff={() => {}}
          dropoffLabel={offer.arrival?.district}
          dropoffSecondaryLabel={offer.arrival.region}
          formattedDepartureTime={formatTime(offer.departureTime)}
        />
      )}

      <Divider paddingVertical={20} color={GREY_100} />

      {offer && (
        <div
          style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}
        >
          <Typography
            color='secondary'
            type='subtitle3'
            style={{ flexGrow: 1 }}
          >
            Narx 1 kishiga
          </Typography>
          <Typography type='title3'>
            {formatPrice(offer.price.amount)}
          </Typography>
        </div>
      )}

      <Divider paddingVertical={20} color={GREY_100} />

      <Typography type='title2'>So&apos;rovlar</Typography>
      {offer?.bookingRequests.length ? (
        <ul>
          {offer.bookingRequests.map((booking) => (
            <BookingRequestItem
              key={booking.id}
              bookingRequest={booking}
              onConfirm={() => handleConfirmBooking(booking.id)}
              onReject={() => handleCancelBooking(booking.id)}
            />
          ))}
        </ul>
      ) : (
        <Typography type='subtitle4' color='secondary'>
          Horizcha mavjud emas
        </Typography>
      )}

      <Divider paddingVertical={20} color={GREY_100} />

      <Typography type='title2'>Yo&apos;lovchilar</Typography>
      {offer?.passengers.length ? (
        <ul>
          {offer.passengers.map((passenger) => (
            <li key={passenger.id} style={{ margin: '10px 0' }}>
              <DriverDetailsCard
                avatar={passenger.photo}
                displayName={passenger.firstName}
                onClick={() => navigate(`/user/${passenger.id}`)}
              />
            </li>
          ))}
        </ul>
      ) : (
        <Typography type='subtitle4' color='secondary'>
          Horizcha mavjud emas
        </Typography>
      )}
    </PageTemplate>
  );
};

export default MyRideDetailsPage;
