import { memo } from 'react';
import { Controller } from 'react-hook-form';

import {
  ErrorMessage,
  FileUploadButton,
  FileUploadContainer,
  FileUploadIcon,
  InputWrapper,
  Label,
  StyledFileInput,
  UploadedImage,
} from './styles';

const ImageUploadController = ({
  control,
  name,
  label,
  placeholder,
}: {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const images = Array.from(field.value as FileList);

        return (
          <InputWrapper>
            <Label>{label}</Label>
            <FileUploadContainer>
              <StyledFileInput
                {...field}
                id='fileInput'
                multiple
                type='file'
                accept='image/*'
                placeholder={placeholder}
                value={field.value?.filename}
                onChange={(event) => {
                  return field.onChange(event.target.files);
                }}
              />
              <FileUploadButton htmlFor='fileInput'>
                {images?.length ? (
                  images.map((image, index) => (
                    <UploadedImage
                      key={index}
                      alt='vehicle image'
                      src={URL.createObjectURL(image)}
                    />
                  ))
                ) : (
                  <div>
                    <FileUploadIcon />
                  </div>
                )}
              </FileUploadButton>
            </FileUploadContainer>

            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </InputWrapper>
        );
      }}
    />
  );
};

export default memo(ImageUploadController);
