import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { ErrorResponseType, MakeBookingParams } from 'common/types';
import { ENDPOINTS, axiosInstance } from 'common/axios';
// import { ErrorResponseType, MakeBookingParams } from 'common/types';

export const makeBookingThunk: AsyncThunkPayloadCreator<
  any,
  MakeBookingParams,
  { rejectValue: ErrorResponseType }
> = async (params, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(ENDPOINTS.makeBooking, params);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const getBookingByIdThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponseType }
> = async (bookingId, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(
      ENDPOINTS.getBookingById(bookingId),
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const confirmBookingThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponseType }
> = async (bookingId, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(
      ENDPOINTS.confirmBooking(bookingId),
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const cancelBookingThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponseType }
> = async (bookingId, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete(
      ENDPOINTS.cancelBooking(bookingId),
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
