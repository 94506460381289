import { VehicleType } from './vehicle';

export enum UserRole {
  PASSENGER = 'passenger',
  DRIVER = 'driver',
  ADMIN = 'admin',
}

export enum LanguageType {
  UZ = 'uz',
  EN = 'en',
  RU = 'ru',
}

export enum UserGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum UserPreference {
  NO = 'NO',
  YES = 'YES',
  MAYBE = 'MAYBE',
}

export type UserType = {
  id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  photo: null | string;
  phoneNumber: string;
  role: UserRole;
  gender: UserGender;
  smoking: UserPreference;
  music: UserPreference;
  dialog: UserPreference;
  vehicle: VehicleType | null;
};

export interface UserProfile {
  id: string;
  firstName: string;
  lastName: string;
  birthDate: string | null;
  photo: string | null;
  phoneNumber: string;
  role: UserRole;
  gender: UserGender;
  smoking: UserPreference;
  music: UserPreference;
  dialog: UserPreference;
}
