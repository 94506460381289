import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { hotjar } from 'react-hotjar';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import { ThemeProvider } from 'styled-components';

import { getEnvironment } from './services';
import { Environment } from './common/types';
import { useAppDispatch } from './common/store';
import { getIsLoggedIn } from './common/store/selectors';
import { AsyncThunks } from './common/store/thunks';
import { accountActions, offerActions } from './common/store/slices';
import { COOKIES_KEYS } from 'appConstants';
import { theme } from './styles';
import { AppRouter } from './router';

const environment = getEnvironment();

if (environment !== Environment.DEVELOPMENT) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '');
  hotjar.initialize(parseInt(process.env.REACT_APP_HOTJAR_SITE_ID || ''), 6);
}

function App() {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  // const { locations } = useSelector(getLocations);

  const fetchLocations = async () => {
    // if (!locations.length) {
    await dispatch(AsyncThunks.getLocations());
    // }
  };

  const clearData = () => {
    const refreshToken = Cookies.get(COOKIES_KEYS.refreshToken);
    if (isLoggedIn && !refreshToken) {
      dispatch(accountActions.reset());
      dispatch(offerActions.reset());
    }
  };

  useEffect(() => {
    fetchLocations();
    clearData();
  }, []);

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     const timeout = setTimeout(() => {
  //       showLoginModal();
  //     }, 2000);
  //     return () => clearTimeout(timeout);
  //   }
  // }, [isLoggedIn]);

  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
