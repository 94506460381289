import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from 'assets/svg';
import Loader from 'components/Loader/Loader';
import {
  PageTitleWrapper,
  PageContentWrapper,
  PageTitle,
  PageWrapper,
} from './PageTemplate.styles';

interface Props {
  isLoading?: boolean;
  children: React.ReactNode;
  backgroundColor?: string;
  showBackButton?: boolean;
  style?: React.CSSProperties;
  title?: string;
}

const PageTemplate = ({
  backgroundColor,
  children,
  isLoading,
  showBackButton,
  style,
  title,
}: Props) => {
  const navigate = useNavigate();

  return (
    <PageWrapper backgroundColor={backgroundColor}>
      <PageContentWrapper style={style}>
        {isLoading && <Loader />}

        {!isLoading && title && (
          <PageTitleWrapper>
            {showBackButton && <ArrowLeftIcon onClick={() => navigate(-1)} />}
            {title && <PageTitle>{title}</PageTitle>}
          </PageTitleWrapper>
        )}

        {!isLoading && children}
      </PageContentWrapper>
    </PageWrapper>
  );
};

export default PageTemplate;
