import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  getAccountLoader,
  getIsLoggedIn,
  getAccountError,
} from 'common/store/selectors';
import { useAppDispatch } from 'common/store';
import { accountActions } from 'common/store/slices';
import { ROUTES } from 'router';
import { useAuth } from 'hooks';
import LoginForm from 'components/Forms/LoginForm';
import RegistrationForm from 'components/Forms/RegistrationForm';
import { PageContentWrapper } from 'styles/common';
import { SwitchButton } from './LoginPage.styles';

const LoginPage = () => {
  const { registerUser, loginUser } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoginForm, setIsLoginForm] = useState<boolean>(true);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLoading = useSelector(getAccountLoader);
  const authError = useSelector(getAccountError);

  const { t } = useTranslation();
  const handleFormChange = () => {
    setIsLoginForm(!isLoginForm);
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROUTES.HOME);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (authError) {
      dispatch(accountActions.clearError());
    }
    return () => {
      dispatch(accountActions.clearError());
    };
  }, [isLoginForm]);

  return (
    <PageContentWrapper>
      <div style={{ display: 'flex', justifyContent: 'end', paddingRight: 10 }}>
        <SwitchButton onClick={handleFormChange}>
          {isLoginForm ? t('Register') : t('Sign in')}
        </SwitchButton>
      </div>

      {isLoginForm ? (
        <LoginForm
          loginUser={loginUser}
          authError={authError}
          isLoading={isLoading}
        />
      ) : (
        <RegistrationForm
          registerUser={registerUser}
          authError={authError}
          isLoading={isLoading}
        />
      )}
    </PageContentWrapper>
  );
};

export default LoginPage;
