import { UserPreference } from 'common/types';

const smoking = {
  YES: "Muammo yo'q, chekishingiz mumkin",
  NO: 'Iltimos, mashinada chekmang',
  MAYBE: 'Mashinadan tashqarida chekish mumkin',
};

const music = {
  YES: "Musiqaga yo'q demasdim",
  NO: 'Sokinlikka nima yetsin',
  MAYBE: "Kayfiyatim yaxshi bo'lsa eshitaman",
};

const dialog = {
  YES: 'Suhbatlashish jonu dilim',
  NO: "Kamgaplikni ma'qul ko'raman",
  MAYBE: "O'zimni yaxshi his qilsam suhbatlashaman",
};

export const getPreferenceLabel = (
  type: 'smoking' | 'music' | 'dialog',
  preference: UserPreference,
): string => {
  switch (type) {
    case 'smoking':
      return smoking[preference];
    case 'music':
      return music[preference];
    case 'dialog':
      return dialog[preference];
    default:
      return 'Not defined';
  }
};
