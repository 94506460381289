import { useTranslation } from 'react-i18next';

import { CustomButton, Typography } from 'components';
import { SuccessContentWrapper } from './styles';

const CreateRequestSuccessContent = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <SuccessContentWrapper>
      <Typography type='subtitle2'>
        {t('Your request was successfully created!')}
      </Typography>
      <CustomButton onClick={closeModal}>{t('Continue')}</CustomButton>
    </SuccessContentWrapper>
  );
};

export default CreateRequestSuccessContent;
