import styled from 'styled-components';
import { FONTS } from 'styles';
import { Typography } from 'components';
import { GREY_500, RED_300, WHITE } from 'styles/colors';

export const FormWrapper = styled.form<{
  showShadow?: boolean;
}>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  padding: 20px 15px;
  margin: 0 auto;
  border-radius: 20px;
  max-width: 620px;
  gap: 20px;

  box-shadow: ${({ showShadow }) =>
    showShadow ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : ''};
`;

export const FormTitle = styled.h1`
  font-family: ${FONTS.Helvetica.bold};
  font-size: 1.25rem;
  text-align: center;
  text-transform: uppercase;
  color: ${GREY_500};
  padding-bottom: 20px;
`;

export const ErrorMessage = styled(Typography)`
  font-size: 0.75rem;
  color: ${RED_300};
`;
