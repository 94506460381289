import { memo, ReactNode } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU, enUS } from '@mui/x-date-pickers/locales';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale';

import { LanguageType } from 'common/types';

const CustomLocalizationProvider = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();

  const getLocaleText = () => {
    switch (i18n.language) {
      case LanguageType.EN:
        return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
      case LanguageType.RU:
        return ruRU.components.MuiLocalizationProvider.defaultProps.localeText;
      default:
        return {
          cancelButtonLabel: 'Bekor qilish',
          datePickerToolbarTitle: 'Sanani tanlash',
          dateTimePickerToolbarTitle: 'Sana va vaqtni tanlash',
        };
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language}
      localeText={getLocaleText()}
    >
      {children}
    </LocalizationProvider>
  );
};

export default memo(CustomLocalizationProvider);
