import styled from 'styled-components';
import { FONTS } from 'styles';
import { GREY_100, GREY_300, GREY_400, GREY_500 } from 'styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  height: 100vh;
  padding-top: 25px;
  padding-bottom: 150px;

  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  margin: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const LocationInputWrapper = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    width: 24px;
    height: 28px;
    margin-right: 10px;
    path {
      stroke: ${GREY_400};
    }
  }
`;

export const LocationList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const LocationItemWrapper = styled.li<{
  isSelected: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  border-radius: 10px;
  cursor: pointer;

  background-color: ${({ isSelected }) => isSelected && GREY_100};
  transition: 0.4s;

  & path {
    stroke: ${GREY_400};
  }

  &:hover {
    opacity: 0.5;
    background-color: ${GREY_100};
  }
`;

export const LocationItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const LocationItemLabel = styled.p<{
  isSelected: boolean;
}>`
  font-family: ${FONTS.Helvetica.medium};
  font-size: 1.125rem;
  color: ${({ isSelected }) => (isSelected ? GREY_500 : GREY_400)};
`;

export const LocationItemDescription = styled.p<{
  isSelected: boolean;
}>`
  font-family: ${FONTS.Helvetica.regular};
  font-size: 0.875rem;
  color: ${({ isSelected }) => (isSelected ? GREY_400 : GREY_300)};
`;

export const LocationEmptyText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;

  font-size: 1.25rem;
  font-family: ${FONTS.Helvetica.medium};
  color: ${GREY_300};
`;
