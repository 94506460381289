import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'router';
import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import { getAccountError, getAccountLoader } from 'common/store/selectors';
import { AddVehicleFormFieldsType, AddVehicleParamsType } from 'common/types';
import { addVehicleSchema } from 'helpers/validationSchemas';
import { FormTemplate, PageTemplate, CustomButton } from 'components';
import { FormInputController, ImageUploadController } from 'components/Inputs';

const AddVehiclePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userError = useSelector(getAccountError);
  const isLoading = useSelector(getAccountLoader);

  const defaultValues: AddVehicleFormFieldsType = {
    model: '',
    number: '',
    images: {},
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(addVehicleSchema),
  });

  const { control, handleSubmit } = methods;

  const handleAddVehicle = async (params: AddVehicleParamsType) => {
    const response = await dispatch(AsyncThunks.addVehicle(params));
    if (!response.payload?.error) {
      navigate(ROUTES.ACCOUNT);
    }
  };

  const onSubmit: SubmitHandler<AddVehicleFormFieldsType> = async (
    data: AddVehicleFormFieldsType,
  ) => {
    if (data) {
      const formData = new FormData();
      formData.append('model', data.model);
      formData.append('number', data.number);

      Array.from(methods.getValues('images') as FileList)?.forEach((image) => {
        formData.append('images', image);
      });

      await handleAddVehicle(formData);
    }
  };

  return (
    <PageTemplate title='Yangi transport' showBackButton>
      <FormTemplate
        onSubmit={handleSubmit(onSubmit)}
        errorMessage={userError?.message}
      >
        <FormInputController
          control={control}
          name='model'
          placeholder='Malibu 2'
          type='text'
          label='Transport modeli'
        />
        <FormInputController
          control={control}
          placeholder='AB777D'
          name='number'
          type='text'
          label='Transport raqami'
        />

        <ImageUploadController
          control={control}
          name='images'
          label='Transport rasmi'
        />

        <CustomButton
          type='submit'
          isLoading={isLoading}
          style={{ marginTop: 10 }}
        >
          {t('Confirm')}
        </CustomButton>
      </FormTemplate>
    </PageTemplate>
  );
};

export default AddVehiclePage;
