import styled from 'styled-components';
import { CrossIcon } from 'assets/svg';
import { FONTS } from 'styles';
import { Typography } from 'components';
import { GREY_300, GREY_500 } from 'styles/colors';

export const Cross = styled(CrossIcon)`
  position: absolute;
  top: 22px;
  right: 20px;
  cursor: pointer;
  z-index: 5;
`;

export const Title = styled.h1`
  font-family: ${FONTS.Helvetica.bold};
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  color: ${GREY_500};
`;

export const SwitchButton = styled(Typography)`
  width: fit-content;
  color: ${GREY_300};
  font-family: ${FONTS.Helvetica.medium};
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding: 6px 0;
  cursor: pointer;
`;
