import { memo, ReactNode } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface IProfileLink {
  children: ReactNode;
  to?: string;
  onClick?: (prop: any) => any;
  style?: any;
  className?: string;
}

const CustomNavLink = ({
  children,
  to,
  onClick,
  style,
  className,
}: IProfileLink) => {
  const location = useLocation();
  const navigateTo = location.pathname;

  return (
    <NavLink
      to={to || navigateTo}
      state={{ prevPath: location.pathname }}
      onClick={onClick}
      style={style}
      className={className}
    >
      {children}
    </NavLink>
  );
};

export default memo(CustomNavLink);
