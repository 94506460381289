import styled from 'styled-components';

import { BREAKPOINTS, FONTS } from 'styles';
import { GREY_400, WHITE } from 'styles/colors';

export const DateSection = styled.div`
  background-color: linear-gradient(to bottom, #eee 48%, #fff);
  margin: 0 auto;
`;

export const DateTabsWrapper = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  gap: 20px;
  padding: 20px;

  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    background-color: ${WHITE};
  }
`;

export const DateTab = styled.button<{
  isSelected?: boolean;
}>`
  min-width: 90px;
  min-height: 40px;
  font-size: 0.875rem;
  border: none;
  font-family: ${FONTS.Helvetica.medium};
  color: ${GREY_400};
  border-radius: 4px;
  padding: 4px 8px;
  background-color: ${({ isSelected }) => (isSelected ? '#86d3ffa8' : WHITE)};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
  transition: 0.4s;

  &:hover {
    background-color: #86d3ffa8;
  }
`;

export const OfferListingSummary = styled.div`
  padding: 0 20px;
  margin: 30px auto 16px;
`;

export const OfferListWrapper = styled.div`
  flex: 1 1 0%;
  display: flex;
  padding: 0 20px;
  gap: 20px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    flex-direction: column;
  }
`;
