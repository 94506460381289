import { ReactNode } from 'react';
import { createSlice, current } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { ModalIds } from 'common/types';

type ModalComponentType = {
  id: ModalIds;
  modalContent: ReactNode;
  boxStyle: any;
  offsetY?: number;
  actionOnClose?: () => void;
};

type ModalType = {
  modal: ModalComponentType;
  isOpen: boolean;
};

const initialState: { modals: ModalType[] } = {
  modals: [
    {
      isOpen: false,
      modal: {
        id: null,
        modalContent: '',
        boxStyle: {},
        offsetY: 0,
        actionOnClose: () => {},
      },
    },
  ],
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action: { payload: ModalComponentType }) => {
      document.body.style.overflow = 'hidden';

      const modalToOpen = state.modals.find(({ modal }) => {
        return modal.id === action.payload.id;
      });

      if (!isEmpty(modalToOpen)) {
        state.modals = current(state).modals.map((modalItem) => {
          if (modalItem.modal.id === action.payload.id) {
            return {
              modal: action.payload,
              isOpen: true,
            };
          }
          return modalItem;
        });
      } else {
        state.modals.push({
          modal: action.payload,
          isOpen: true,
        });
      }
    },
    hideModal: (state, action: { payload: ModalIds }) => {
      document.body.style.overflow = 'auto';

      state.modals = state.modals.map((modalItem) => {
        if (modalItem.modal.id === action.payload) {
          return {
            ...modalItem,
            isOpen: false,
          };
        }
        return modalItem;
      });
    },

    hideAllModals: (state) => {
      document.body.style.overflow = 'auto';

      state.modals = state.modals.map((modalItem) => ({
        ...modalItem,
        isOpen: false,
      }));
    },
  },
});

export const modalActions = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
