import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getEnvironment } from 'services';
import { Environment } from 'common/types';
import { LOCAL_STORAGE_KEYS } from '../appConstants';

const defaultLang = window.localStorage.getItem(LOCAL_STORAGE_KEYS.language);

const environment = getEnvironment();

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: require('./locales/en/translations.json') },
    ru: { translation: require('./locales/ru/translations.json') },
    uz: { translation: require('./locales/uz/translations.json') },
  },
  fallbackLng: defaultLang || 'uz',
  defaultNS: 'translation',
  debug: environment === Environment.DEVELOPMENT ? true : false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
