import { createSlice } from '@reduxjs/toolkit';
import { SearchOffersApiResponse, StateType } from 'common/types';
import { onError, onPending } from '../stateResults';
import { AsyncThunks } from '../thunks';

const initialState: StateType<SearchOffersApiResponse> = {
  pending: false,
  error: null,
  result: { offers: [], totalCount: 0 },
};

const offerSearchSlice = createSlice({
  name: 'offerSearch',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.searchOffers.pending, onPending);
    builder.addCase(AsyncThunks.searchOffers.fulfilled, (state, action) => {
      state.pending = false;
      state.result = action.payload;
    });
    builder.addCase(AsyncThunks.searchOffers.rejected, onError);
  },
});

export const offerSearchActions = offerSearchSlice.actions;
export const offerSearchReducer = offerSearchSlice.reducer;
