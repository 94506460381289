import styled from 'styled-components';
import { FONTS } from 'styles';
import { Typography } from 'components';
import { RED_100, WHITE } from 'styles/colors';

export const BlockTitle = styled(Typography).attrs({
  type: 'title2',
})`
  padding: 40px 0 10px;
`;

export const CarDetailsWrapper = styled.div`
  padding: 16px 20px;
  background-color: ${WHITE};
  position: relative;
  margin-top: 10px;
`;

export const LogoutButton = styled.button`
  all: unset;
  cursor: pointer;
  font-size: 0.875rem;
  font-family: ${FONTS.Helvetica.medium};
  color: ${RED_100};
  margin-top: 40px;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  path {
    stroke: ${RED_100};
  }

  &:hover {
    opacity: 0.8;
  }
`;
