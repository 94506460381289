import styled from 'styled-components';
import { FONTS } from 'styles';
import { GREY_500, WHITE } from 'styles/colors';

export const OfferCardWrapper = styled.div`
  width: 100%;
  min-width: 220px;
  max-width: 600px;
  background-color: ${WHITE};
  box-shadow: 0px 2px 8px 0px hsla(0, 0%, 0%, 0.2);
  padding: 15px;
  border-radius: 20px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
`;

export const OfferPrice = styled.span`
  font-family: ${FONTS.Helvetica.medium};
  font-size: 0.875rem;
  color: ${GREY_500};
  text-align: right;
`;

export const OfferImage = styled.img`
  width: 120px;
  height: 120px;
`;
