import {
  DriverDescription,
  DriverInfoSection,
  DriverName,
  RightTextWrapper,
  SChevronRightIcon,
} from './DriverDetailsCard.styles';
import Avatar from 'components/Avatar/Avatar';

interface Props {
  avatar?: string | null;
  displayName: string;
  description?: string;
  onClick?: () => void;
}

const DriverDetailsCard = ({
  displayName,
  description,
  avatar,
  onClick,
}: Props) => {
  return (
    <DriverInfoSection onClick={onClick}>
      <Avatar src={avatar} />

      <RightTextWrapper>
        <DriverName>{displayName}</DriverName>
        {description && <DriverDescription>{description}</DriverDescription>}
      </RightTextWrapper>

      <SChevronRightIcon />
    </DriverInfoSection>
  );
};

export default DriverDetailsCard;
