import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useModals } from 'hooks';
import { getUserVehicle } from 'common/store/selectors';
import { PageTemplate } from 'components';
import { ROUTES } from 'router';
import {
  ServiceItem,
  ServiceItemImage,
  ServiceItemTitle,
  ServicesList,
} from './styles';

const ServicesPage = () => {
  const navigate = useNavigate();
  const { showAddVehicleModal } = useModals();
  const vehicle = useSelector(getUserVehicle);
  const { t } = useTranslation();

  const handleNavigateToCreateOffer = () => {
    if (!vehicle) return showAddVehicleModal();
    navigate(ROUTES.CREATE_OFFER);
  };

  return (
    <PageTemplate title={`${t('I need a')}...`}>
      <ServicesList>
        {/* <ServiceItem onClick={() => navigate(ROUTES.CREATE_REQUEST)}>
          <ServiceItemImage
            src={require('../../assets/jpg/taxi.jpeg')}
            alt='taxi-icon'
          />
          <ServiceItemTitle>{t('Driver')}</ServiceItemTitle>
        </ServiceItem> */}

        <ServiceItem onClick={handleNavigateToCreateOffer}>
          <ServiceItemImage
            src={require('../../assets/png/passenger.png')}
            alt='passenger-icon'
          />
          <ServiceItemTitle>{t('Passenger')}</ServiceItemTitle>
        </ServiceItem>
      </ServicesList>
    </PageTemplate>
  );
};

export default ServicesPage;
