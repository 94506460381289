import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { FONT_STYLES, TextColors, TextColorType } from 'styles/fontStyles';

interface TypographyProps {
  tag?: keyof JSX.IntrinsicElements;
  children: ReactNode;
  type?: keyof typeof FONT_STYLES;
  color?: TextColorType;
  style?: any;
  onClick?: (prop: any) => any;
  className?: any;
}

const StyledTypography = styled.p<TypographyProps>`
  ${(props) => getCssFontStyles(props.type)}
  color: ${(props) => TextColors[props.color || 'primary']};
`;

const getCssFontStyles = (type?: keyof typeof FONT_STYLES) => {
  if (type) {
    const fontStyles = FONT_STYLES[type];
    return css`
      font-size: ${fontStyles.fontSize};
      line-height: ${fontStyles.lineHeight};
      font-family: ${fontStyles.fontFamily};
    `;
  }

  return '';
};

const Typography = ({
  children,
  tag,
  color,
  type,
  className,
  ...rest
}: TypographyProps) => {
  return (
    <StyledTypography
      as={tag}
      tag={tag}
      type={type}
      color={color}
      className={className}
      {...rest}
    >
      {children}
    </StyledTypography>
  );
};

export default Typography;
