import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import { accountActions } from 'common/store/slices';
import { getIsLoggedIn, getRegistrationResult } from 'common/store/selectors';
import { LoginParamsType, RegisterParamsType } from 'common/types';
import { COOKIES_KEYS } from 'appConstants';

const useAuth = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const registrationResult = useSelector(getRegistrationResult);
  const [credentials, setCredentials] = useState<LoginParamsType>();

  const registerUser = useCallback(
    async (registerParams: RegisterParamsType) => {
      setCredentials({
        phoneNumber: registerParams.phoneNumber,
        password: registerParams.password,
      });

      await dispatch(AsyncThunks.registerUser(registerParams));
    },
    [dispatch],
  );

  const loginUser = useCallback(
    async (loginParams: LoginParamsType) => {
      await dispatch(AsyncThunks.loginUser(loginParams));
    },
    [dispatch],
  );

  const logoutUser = useCallback(async () => {
    Cookies.remove(COOKIES_KEYS.accessToken);
    Cookies.remove(COOKIES_KEYS.refreshToken);
    setTimeout(() => dispatch(accountActions.reset()), 1000);
  }, [dispatch]);

  useEffect(() => {
    if (
      !isLoggedIn &&
      credentials &&
      registrationResult?.status === 'success'
    ) {
      loginUser(credentials);
    }
  }, [credentials, loginUser, registrationResult]);

  return { registerUser, loginUser, logoutUser };
};

export default useAuth;
