import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'router';
import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import { getMyOffersAndBookings, getOfferLoader } from 'common/store/selectors';
import { EmptyPageBanner, MyOfferItem, PageTemplate } from 'components';
import RidePlanningImage from 'assets/png/ride-planning.png';
import { OffersWrapper } from './MyRidesPage.styles';

const MyRidesPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalCount, offers } = useSelector(getMyOffersAndBookings);
  const isLoading = useSelector(getOfferLoader);

  const fetchMyOffersAndBookings = () => {
    dispatch(AsyncThunks.getMyOffers());
  };

  const handleNavigateOfferItem = (offerId: number) => {
    navigate(`${ROUTES.MY_RIDES}/${offerId}`);
  };

  useEffect(() => {
    fetchMyOffersAndBookings();
  }, []);

  return (
    <PageTemplate
      title={totalCount > 0 ? 'Mening sayohatlarim' : ''}
      isLoading={isLoading}
    >
      {!totalCount && (
        <EmptyPageBanner
          imageSrc={RidePlanningImage}
          title={t('Your future rides will appear here')}
        />
      )}

      <OffersWrapper>
        {offers.map((offer) => (
          <MyOfferItem
            key={offer.id}
            offer={offer}
            onClick={() => handleNavigateOfferItem(offer.id)}
          />
        ))}
      </OffersWrapper>
    </PageTemplate>
  );
};

export default MyRidesPage;
