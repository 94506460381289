import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'router';
import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import { getIsLoggedIn, getOfferLoader } from 'common/store/selectors';
import { SearchOfferParamsType } from 'common/types';
import { useGoogleAnalytics } from 'hooks';
import SearchOfferForm from 'components/Forms/SearchOfferForm';
import { CustomButton, Seo, Typography } from 'components';
import { CoinsIcon, PassportIcon, ZapIcon } from 'assets/svg';
import { ContentWrapper } from 'styles/common';
import {
  Wrapper,
  FormSectionWrapper,
  CTASectionWrapper,
  InfoIconCardList,
  InfoIconCardItem,
  IconWrapper,
  Title,
  MainImage,
  ImageWrapper,
  BlueBackground,
} from './styles';

const strengths = [
  {
    icon: <CoinsIcon />,
    title: 'Cheap prices',
    subtitle:
      'Discover budget-friendly journeys for your car trips, no matter where your destination lies. Explore our cost-effective options on extensive routes.',
  },
  {
    icon: <PassportIcon />,
    title: 'Security and trust',
    subtitle:
      "We take the time to get to know each driver and passenger. We check profiles and reviews so you know who you're traveling with.",
  },
  {
    icon: <ZapIcon />,
    title: 'Everything is convenient and fast',
    subtitle:
      'Booking a taxi has never been easier! But thanks to our simple application with great technological solutions, you can book your convenient trip in minutes.',
  },
];

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isSearchLoading = useSelector(getOfferLoader);
  // const { showLoginModal } = useAuthModals();
  const { addPageView } = useGoogleAnalytics();
  const { t } = useTranslation();

  const handleNavigationToRequestForm = () => {
    if (!isLoggedIn) {
      // showLoginModal();
      return;
    }
    navigate(ROUTES.CREATE_REQUEST);
  };

  const handleSearchFormSubmit = async (params: SearchOfferParamsType) => {
    const response = await dispatch(AsyncThunks.searchOffers(params));
    if (response?.payload) {
      const queryParams = new URLSearchParams(params).toString();
      navigate(`${ROUTES.OFFER_LISTING}/?${queryParams}`, {
        state: { fromSearch: true },
      });
    }
  };

  useEffect(() => {
    addPageView('Home');
  }, []);

  return (
    <Wrapper>
      <Seo
        title='Shaharlar, va viloyatlar aro qulay narxlardagi qatnovlar | Taksifon'
        description="Shaharlar bo'ylab qulay narxlardagi qatnovlar. Uyingizdan chiqmasdan taksi buyurtma qiling. Biz bilan hammasi qulay va arzon!"
        keywords='taksifon, onlayn taksi, taksi zakaz, taxifon, taxi zakaz, taxi online'
      />

      <div style={{ position: 'relative' }}>
        <BlueBackground />
        <FormSectionWrapper>
          <Title>{t('Find the perfect ride with super low-cost')}</Title>
          <SearchOfferForm
            // title={t('Search taxi')}
            isLoading={isSearchLoading}
            handleFormSubmit={handleSearchFormSubmit}
          />
        </FormSectionWrapper>
      </div>

      <ImageWrapper>
        <MainImage imageUrl={require('../../assets/png/illustration4.png')} />
      </ImageWrapper>

      <CTASectionWrapper>
        <Typography type='body1' color='secondary'>
          {t(
            "Couldn't find a taxi in the direction you were looking for on the search page? Don't worry, you have the option to create an order directly for drivers!",
          )}
        </Typography>
        <CustomButton
          style={{ marginTop: 20, maxWidth: 400 }}
          onClick={handleNavigationToRequestForm}
        >
          {t('Order a taxi')}
        </CustomButton>
      </CTASectionWrapper>

      <ContentWrapper>
        <InfoIconCardList>
          {strengths.map(({ icon, title, subtitle }, index) => (
            <InfoIconCardItem key={index}>
              <IconWrapper>{icon}</IconWrapper>
              <Typography type='title3'>{t(title)}</Typography>
              <Typography type='body2' color='secondary'>
                {t(subtitle)}
              </Typography>
            </InfoIconCardItem>
          ))}
        </InfoIconCardList>
      </ContentWrapper>
    </Wrapper>
  );
};

export default HomePage;
