// theme.ts
const theme = {
  buttonSizes: {
    small: {
      padding: '0 10px',
      fontSize: '0.625rem',
      height: '38px',
    },
    medium: {
      padding: '0 20px',
      fontSize: '0.75rem',
      height: '40px',
    },
    large: {
      padding: '0 30px',
      fontSize: '0.875rem',
      height: '50px',
    },
  },
};

export default theme;
