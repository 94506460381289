import { Dispatch, SetStateAction, useState } from 'react';
import {
  BaseSingleInputFieldProps,
  DatePicker,
  DatePickerProps,
  DateValidationError,
  FieldSection,
  UseDateFieldProps,
} from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import { formatDate, formatDateWithWords } from 'helpers/date';
import CustomLocalizationProvider from './CustomLocalizationProvider';
import { CalendarIcon } from 'assets/svg';
import CustomInput from './CustomInput';

interface InputFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const InputFieldView = ({
  setOpen,
  label,
  id,
  InputProps: { ref } = {},
  inputProps: { 'aria-label': ariaLabel } = {},
}: InputFieldProps) => {
  const formattedDateValue = formatDateWithWords(label?.toString());

  return (
    <div id={id} ref={ref} aria-label={ariaLabel} style={{ flex: 1 }}>
      <CustomInput
        Icon={CalendarIcon}
        value={label ? formattedDateValue : ''}
        onClick={() => setOpen?.((prev) => !prev)}
      />
    </div>
  );
};

const CustomDatePicker = ({
  date,
  setDate,
  dateProps,
}: {
  date: any;
  setDate: Dispatch<SetStateAction<any>>;
  dateProps?: DatePickerProps<any>;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <CustomLocalizationProvider>
      <DatePicker
        {...dateProps}
        slots={{ field: InputFieldView }}
        label={date == null ? null : date}
        slotProps={{ field: { setOpen } as any, toolbar: { hidden: true } }}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        value={dayjs(date)}
        onChange={(newValue) => {
          if (newValue) {
            setDate(formatDate(newValue));
          } else {
            setDate('');
          }
        }}
      />
    </CustomLocalizationProvider>
  );
};

export default CustomDatePicker;
