import styled from 'styled-components';

const DividerWrapper = styled.div<{
  paddingVertical?: number;
}>`
  padding: ${({ paddingVertical }) =>
    paddingVertical ? `${paddingVertical}px 0` : '8px 0'};
`;

const StyledDivider = styled.div<Props>`
  height: ${({ height }) => (height ? `${height}px` : '8px')};
  background-color: ${({ color }) => color || 'transparent'};
  border-radius: 20px;
`;

interface Props {
  height?: number;
  paddingVertical?: number;
  color?: string;
}

const Divider = ({ height, color, paddingVertical }: Props) => (
  <DividerWrapper aria-hidden paddingVertical={paddingVertical}>
    <StyledDivider height={height} color={color} />
  </DividerWrapper>
);

export default Divider;
