import styled from 'styled-components';
import { FONTS } from 'styles';
import { RED_200, WHITE } from 'styles/colors';

export const ConnectionStatusWrapper = styled.div`
  padding: 6px;
  color: ${WHITE};
  background-color: ${RED_200};
  font-family: ${FONTS.Helvetica.regular};
  font-size: 0.75rem;
  text-align: center;
`;
