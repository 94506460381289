import { createSlice } from '@reduxjs/toolkit';

import { AsyncThunks } from '../thunks';
import { onError, onPending } from '../stateResults';
import { Offer, StateType } from 'common/types';

const initialState: StateType<Offer | null> = {
  pending: false,
  error: null,
  result: null,
};

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getOfferDetails.pending, onPending);
    builder.addCase(AsyncThunks.getOfferDetails.fulfilled, (state, action) => {
      state.pending = false;
      state.result = action.payload;
    });
    builder.addCase(AsyncThunks.getOfferDetails.rejected, onError);

    builder.addCase(AsyncThunks.createOffer.pending, onPending);
    builder.addCase(AsyncThunks.createOffer.fulfilled, (state) => {
      state.pending = false;
    });
    builder.addCase(AsyncThunks.createOffer.rejected, onError);
  },
});

export const offerActions = offerSlice.actions;
export const offerReducer = offerSlice.reducer;
