import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import {
  getAccountDetails,
  getAccountLoader,
  getAccountError,
} from 'common/store/selectors';
import { updateUserSchema } from 'helpers/validationSchemas';
import { UpdateUserFormFieldsType, UpdateUserParamsType } from 'common/types';
import { FormTemplate, PageTemplate, CustomButton } from 'components';
import {
  FormInputController,
  BasicDatePickerController,
} from 'components/Inputs';

const AccountEditPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useSelector(getAccountDetails);
  const isLoading = useSelector(getAccountLoader);
  const userError = useSelector(getAccountError);

  const defaultValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    birthDate: user?.birthDate || '',
    phoneNumber: user?.phoneNumber || '',
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(updateUserSchema),
  });

  const { control, handleSubmit } = methods;

  const handleUpdateUser = (params: UpdateUserParamsType) => {
    dispatch(AsyncThunks.updateUser(params));
  };

  const onSubmit: SubmitHandler<UpdateUserFormFieldsType> = (
    data: UpdateUserFormFieldsType,
    e: any,
  ) => {
    if (data) {
      handleUpdateUser({
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        birthDate: data.birthDate,
      });
    }
    e.target.reset();
  };

  return (
    <PageTemplate
      title={t('Edit profile details')}
      isLoading={isLoading}
      showBackButton
    >
      <FormTemplate
        errorMessage={userError?.message}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormInputController control={control} name='firstName' label='Ism*' />
        <FormInputController
          control={control}
          name='lastName'
          label='Familiya*'
        />
        <FormInputController
          control={control}
          name='phoneNumber'
          label='Telefon raqam*'
        />
        <BasicDatePickerController
          control={control}
          name='birthDate'
          label="Tug'ilgan sana*"
          dateProps={{
            disableFuture: true,
          }}
        />
        <CustomButton
          type='submit'
          isLoading={isLoading}
          style={{ margin: '15px auto 0' }}
        >
          saqlash
        </CustomButton>
      </FormTemplate>
    </PageTemplate>
  );
};

export default AccountEditPage;
