import styled, { css } from 'styled-components';
import { GREY_100, GREY_300, GREY_400, GREY_500, WHITE } from 'styles/colors';
import { FONTS } from 'styles';

export const SummaryWrapper = styled.ul`
  background-color: ${WHITE};
  border-radius: 4px;

  flex-grow: 1;
  flex-shrink: 1;
`;

export const SummaryItem = styled.li<{
  isClickable: boolean;
}>`
  display: flex;
  min-height: 28px;
  padding-left: 10px;

  border-radius: 8px;
  transition: 0.4s;

  ${({ isClickable }) =>
    isClickable &&
    css`
      &:hover {
        background-color: ${GREY_100};
        cursor: pointer;
      }
    `}
`;

export const RouteTime = styled.time`
  width: 48px;
  flex-shrink: 0;
  display: initial;
  padding-top: 10px;
  font-size: 1rem;
  font-family: ${FONTS.Helvetica.bold};
  color: ${GREY_400};
`;

export const LocationBlock = styled.div`
  padding: 8px 22px 8px 0;
  flex: 1 1 0%;
  position: relative;
`;

export const LocationText = styled.span`
  font-size: 1.125rem;
  color: ${GREY_400};
  font-family: ${FONTS.Helvetica.bold};
`;

export const LocationSecondaryText = styled.span`
  font-size: 0.75rem;
  color: ${GREY_300};
  font-family: ${FONTS.Helvetica.medium};
  margin-top: 6px;
  display: block;
`;

export const RouteLineWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 32px;
  margin: 0 8px;
  width: 8px;
  flex-shrink: 0;

  &:last-child {
    flex: 1 1 0%;
  }
`;

export const RouteTopLine = styled.div<{
  isRotated?: boolean;
}>`
  width: 4px;
  height: 15px;
  background: ${({ isRotated }) => (isRotated ? GREY_500 : 'transparent')};
`;

export const RouteBottomLine = styled.div<{
  isRotated?: boolean;
}>`
  width: 4px;
  height: 15px;
  background: ${({ isRotated }) => (isRotated ? 'transparent' : GREY_500)};

  &:last-child {
    flex: 1 1 0%;
  }
`;

export const RouteDotWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
`;

export const RouteDot = styled.div`
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  background-color: ${WHITE};
  border: 2px solid ${GREY_400};
  border-radius: 50%;
`;

export const RouteChevronRight = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: ${GREY_300};
    }
  }
`;
