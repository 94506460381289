import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import store from '..';
import { ENDPOINTS, axiosInstance } from 'common/axios';
import {
  AddVehicleParamsType,
  ErrorResponseType,
  MyOffersApiResponse,
  UpdateUserParamsType,
} from 'common/types';

export const getMeThunk: AsyncThunkPayloadCreator<
  any,
  undefined,
  { rejectValue: ErrorResponseType }
> = async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.getMe);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const getMyOffersThunk: AsyncThunkPayloadCreator<
  MyOffersApiResponse,
  undefined,
  { rejectValue: ErrorResponseType }
> = async (_, { rejectWithValue }) => {
  try {
    const userId = store.getState().accountReducer.userId;

    const response = await axiosInstance.get(ENDPOINTS.getUserOffers(userId));
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response);
  }
};

export const getMyOfferDetailsThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponseType }
> = async (offerId, { rejectWithValue }) => {
  try {
    const userId = store.getState().accountReducer.userId;

    const response = await axiosInstance.get(
      ENDPOINTS.getUserOfferDetails(userId, offerId),
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const updateUserThunk: AsyncThunkPayloadCreator<
  any,
  UpdateUserParamsType,
  { rejectValue: ErrorResponseType }
> = async (userParams, { rejectWithValue }) => {
  try {
    const userId = store.getState().accountReducer.userId;

    const response = await axiosInstance.put(
      ENDPOINTS.getUserById(userId),
      userParams,
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const getUserVehicleThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponseType }
> = async (carId, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(ENDPOINTS.getVehicleById(carId));
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const addVehicleThunk: AsyncThunkPayloadCreator<
  any,
  AddVehicleParamsType,
  { rejectValue: ErrorResponseType }
> = async (params, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(ENDPOINTS.createVehicle, params);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
