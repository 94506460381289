import styled from 'styled-components';
import { Autocomplete, Slider, TextField } from '@mui/material';
import { DatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';

import { Typography } from 'components';
import { ChevronDownIcon, LocationIcon, UploadIcon } from 'assets/svg';
import { GREY_100, GREY_300, GREY_400, WHITE } from 'styles/colors';
import { COLORS, FONTS } from 'styles';

const INPUT_HEIGHT = 50;
const INPUT_BACKGROUND = GREY_100;
const INPUT_BORDER_RADIUS = 20;

export const InputValueText = styled.span`
  font-size: 1.125rem;
  font-family: ${FONTS.Helvetica.medium};
  color: ${GREY_400};
`;

export const InputPlaceholder = styled.span`
  font-size: 1.125rem;
  font-family: ${FONTS.Helvetica.regular};
  color: ${GREY_300};
`;

export const StyledTextField = styled(TextField).attrs({
  variant: 'outlined',
  fullWidth: true,
  size: 'small',
})({
  '& input': {
    padding: '0 12px',
    height: INPUT_HEIGHT,
    fontSize: '1rem',
    color: GREY_400,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: INPUT_BORDER_RADIUS,
    border: 'none',
  },

  '& .MuiOutlinedInput-input': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  '& .MuiOutlinedInput-root': {
    fontFamily: FONTS.Helvetica.regular,
    borderWidth: 1,
    background: INPUT_BACKGROUND,
    borderRadius: INPUT_BORDER_RADIUS,
  },
});

export const StyledAutocomplete = styled(Autocomplete)({
  backgroundColor: INPUT_BACKGROUND,
  height: INPUT_HEIGHT,
  width: '100%',
  position: 'relative',
  borderRadius: INPUT_BORDER_RADIUS,
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: INPUT_BORDER_RADIUS,
    border: 'none',
  },
  '& input': {
    padding: '0 !important',
    height: '19px',
    fontSize: '1rem',
  },
  '& .MuiInputBase-root': {
    height: INPUT_HEIGHT,
  },
  '& .MuiInputLabel-root': {
    fontSize: '1rem',
  },
});

export const StyledDatePicker = styled(DatePicker)({
  '& .MuiInputBase-root': {
    height: 50,
    padding: '0 12px',
    width: '100%',
    backgroundColor: INPUT_BACKGROUND,
    borderRadius: INPUT_BORDER_RADIUS,
    outline: 'none',
  },
  '& .MuiInputBase-input': {
    padding: 0,
    fontSize: '1rem',
    color: COLORS.black,
    fontFamily: FONTS.Helvetica.regular,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

export const StyledMobileDateTimePicker = styled(MobileDateTimePicker)({
  '& .MuiInputBase-root': {
    height: INPUT_HEIGHT,
    padding: '0 8px',
    width: '100%',
    backgroundColor: INPUT_BACKGROUND,
    outline: 'none',
    margin: '0 !important',
    borderRadius: INPUT_BORDER_RADIUS,
  },
  '& .MuiInputBase-input': {
    padding: 0,
    fontSize: '1rem',
    color: COLORS.black,
    fontFamily: FONTS.Helvetica.regular,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-family: ${FONTS.Helvetica.regular};
  color: ${COLORS.black};
  font-size: 0.9375rem;
  opacity: 0.8;
  margin-bottom: 2px;
`;

export const ErrorMessage = styled(Typography).attrs({ color: 'red' })`
  font-size: 0.75rem;
  margin-top: 4px;
`;

export const StyledSlider = styled(Slider)({
  color: '#7b858f !important',
  width: '83% !important',
  margin: '10px auto 0',
  height: 4,
  '& .MuiSlider-root': {
    width: '80% !important',
  },
  '& .MuiSlider-valueLabel': {
    fontFamily: FONTS.Helvetica.regular,
    fontSize: '0.6875rem',
    padding: '4px 8px',
  },
  '& .MuiSlider-markLabel': {
    fontFamily: FONTS.Helvetica.medium,
    fontSize: '0.75rem',
  },
});

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

export const CounterButton = styled.button.attrs({
  type: 'button',
})`
  width: 60px;
  height: 50px;
  font-size: 1.5rem;
  color: ${COLORS.black};
  background-color: #badcffe6;
  border-radius: ${INPUT_BORDER_RADIUS}px;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: auto;
  }
`;

export const CounterCount = styled.div`
  flex-grow: 1;
  height: 50px;
  font-size: 1rem;
  font-family: ${FONTS.Helvetica.medium};
  color: ${COLORS.black};
  background-color: ${INPUT_BACKGROUND};
  border-radius: ${INPUT_BORDER_RADIUS}px;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SChevronDownIcon = styled(ChevronDownIcon)`
  width: 18px;
  height: 18px;
  & path {
    stroke: ${COLORS.black};
  }
`;

export const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledFileInput = styled.input`
  display: none;
`;

export const FileUploadButton = styled.label`
  width: 100%;
  min-height: 100px;
  color: ${COLORS.black};
  background-color: ${WHITE};
  border: 2px dashed ${COLORS.border};
  padding: 20px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 0.875rem;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6px;
`;

export const UploadedImage = styled.img`
  width: 70px;
  height: 70px;
`;

export const FileUploadIcon = styled(UploadIcon)`
  width: 60px;
  height: 60px;

  & path {
    fill: ${COLORS.border};
  }
`;

export const LocationAutocompleteIcon = styled(LocationIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: ${COLORS.black};
    opacity: 0.7;
  }
`;

export const InputContainer = styled.div`
  padding: 8px 20px;
  display: flex;
  flex-direction: row;
  background-color: ${INPUT_BACKGROUND};
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${INPUT_BORDER_RADIUS}px;
  cursor: pointer;
`;

export const InputIconWrapper = styled.div`
  margin-right: 10px;
  & svg {
    width: 24px;
    height: 24px;
  }

  & path,
  & circle {
    stroke: ${GREY_300};
  }
`;
