import { createGlobalStyle } from 'styled-components';
import HelveticaRegular from 'assets/fonts/HelveticaNeue-Roman.otf';
import HelveticaMedium from 'assets/fonts/HelveticaNeue-Medium.otf';
import HelveticaBold from 'assets/fonts/HelveticaNeue-Bold.otf';

import { FONTS } from 'styles';
import { WHITE } from './colors';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: ${FONTS.Helvetica.regular};
    src: local(${FONTS.Helvetica.regular}), url(${HelveticaRegular}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: ${FONTS.Helvetica.medium};
    src: local(${FONTS.Helvetica.medium}), url(${HelveticaMedium}) format('opentype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: ${FONTS.Helvetica.bold};
    src: local(${FONTS.Helvetica.bold}), url(${HelveticaBold}) format('opentype');
    font-weight: 700;
    font-style: normal;
  }
        
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  body {
    background-color: ${WHITE};
  }

  & a {
    text-decoration: none;
  }

  button {
    text-decoration: none;
    background: none;
    cursor: pointer;
  }

  & ol, ul {
    list-style: none;
  }

  & div, p {
    font-family: ${FONTS.Helvetica.regular};
  }
`;
