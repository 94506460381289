import { memo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { DatePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { formatDate } from 'helpers/date';
import CustomLocalizationProvider from './CustomLocalizationProvider';
import { ErrorMessage, InputWrapper, Label, StyledDatePicker } from './styles';

const BasicDatePickerController = ({
  control,
  name,
  label,
  dateProps,
}: {
  control: any;
  name: string;
  label?: string;
  dateProps?: DatePickerProps<any>;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <CustomLocalizationProvider>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <InputWrapper>
              {label && <Label>{label}</Label>}
              <StyledDatePicker
                {...field}
                {...dateProps}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                value={dayjs(field.value)}
                slotProps={{ toolbar: { hidden: true } }}
                onChange={(newValue) => {
                  if (newValue) {
                    field.onChange(formatDate(newValue));
                  } else {
                    field.onChange('');
                  }
                }}
              />
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </InputWrapper>
          );
        }}
      />
    </CustomLocalizationProvider>
  );
};

export default memo(BasicDatePickerController);
