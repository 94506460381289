import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { useAppDispatch } from 'common/store';
import { modalActions } from 'common/store/slices';
import { LocationType } from 'common/types';
import { StyledTextField } from 'components/Inputs/styles';
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/svg';
import {
  Wrapper,
  LocationList,
  LocationItemWrapper,
  LocationItemLabel,
  LocationItemTextWrapper,
  LocationItemDescription,
  LocationEmptyText,
  LocationInputWrapper,
} from './ChooseLocation.styles';

interface Props {
  options: LocationType[];
  location: LocationType | undefined;
  setLocation: Dispatch<SetStateAction<LocationType | undefined>>;
  closeModal: () => void;
}

const ChooseLocation = ({
  options,
  location,
  setLocation,
  closeModal,
}: Props) => {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<LocationType[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (searchTerm === '' && location) {
      setSearchResults([location]);
    }
  }, []);

  useEffect(() => inputRef.current?.focus(), []);

  const handleSearchLocation = (searchTerm: string) => {
    if (!searchTerm) return setSearchResults([]);

    const searchTerms = searchTerm.toLowerCase().split(' ');

    const filteredLocations = options.filter((location) =>
      searchTerms.every(
        (term) =>
          location.region.toLowerCase().includes(term) ||
          location.district.toLowerCase().includes(term),
      ),
    );

    setSearchResults(filteredLocations);
  };

  return (
    <Wrapper>
      <LocationInputWrapper>
        <ChevronLeftIcon
          onClick={() => dispatch(modalActions.hideAllModals())}
        />
        <StyledTextField
          type='text'
          inputRef={inputRef}
          value={searchTerm}
          placeholder='Search location'
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearchLocation(e.target.value);
          }}
        />
      </LocationInputWrapper>

      <LocationList>
        {!searchTerm &&
          options?.length &&
          options.slice(0, 5).map((option) => (
            <LocationItem
              key={option.id}
              label={option.district}
              description={option.region}
              isSelected={location?.id === option.id}
              onClick={() => {
                setLocation(option);
                closeModal();
              }}
            />
          ))}

        {searchResults.length ? (
          searchResults.map((option) => (
            <LocationItem
              key={option.id}
              label={option.district}
              description={option.region}
              isSelected={location?.id === option.id}
              onClick={() => {
                setLocation(option);
                closeModal();
              }}
            />
          ))
        ) : (
          <LocationEmptyText>
            {searchTerm && 'No locations found'}
          </LocationEmptyText>
        )}
      </LocationList>
    </Wrapper>
  );
};

export default ChooseLocation;

interface LocationItemProps {
  isSelected: boolean;
  onClick: () => void;
  label: string;
  description: string;
}

const LocationItem = ({
  label,
  description,
  isSelected,
  onClick,
}: LocationItemProps) => {
  return (
    <LocationItemWrapper isSelected={isSelected} onClick={onClick}>
      <LocationItemTextWrapper>
        <LocationItemLabel isSelected={isSelected}>{label}</LocationItemLabel>
        <LocationItemDescription isSelected={isSelected}>
          {description}
        </LocationItemDescription>
      </LocationItemTextWrapper>

      <ChevronRightIcon />
    </LocationItemWrapper>
  );
};
