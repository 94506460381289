import styled from 'styled-components';
import { BREAKPOINTS, FONTS } from 'styles';
import { ContentWrapper } from 'styles/common';
import { PRIMARY_BLUE_300, WHITE } from 'styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 60px;
  background-color: ${WHITE};
  @media (max-width: ${BREAKPOINTS.s}px) {
    padding-bottom: 30px;
  }
`;

export const ImageWrapper = styled(ContentWrapper)``;

export const Title = styled.h1`
  max-width: 620px;
  margin: 0 auto;
  padding: 20px 0 30px;
  font-size: 1.5rem;
  line-height: 28px;
  font-family: ${FONTS.Helvetica.bold};
  text-transform: uppercase;
  color: ${WHITE};
  text-align: start;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 100%;
    font-size: 1.25rem;
  }
`;

export const MainImage = styled.div<{
  imageUrl: string;
}>`
  width: 100%;
  max-height: 460px;
  min-height: 360px;
  margin: 0 auto;

  background-image: ${({ imageUrl }) => {
    return imageUrl ? `url(${imageUrl})` : '';
  }};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.4s ease;

  @media (max-width: ${BREAKPOINTS.s}px) {
    max-height: 360px;
    min-height: 260px;
  }
`;

export const BlueBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-height: 300px;
  background-image: linear-gradient(
    rgba(1, 121, 163, 0.64) 0%,
    rgba(5, 71, 82, 0) 100%
  );
  background-color: ${PRIMARY_BLUE_300};
`;

export const FormSectionWrapper = styled.div`
  padding: 30px 20px;
  position: relative;
`;

export const CTASectionWrapper = styled(ContentWrapper)`
  padding: 0 15px;
`;

export const InfoIconCardList = styled.ul`
  display: flex;
  flex-direction: row;
  padding: 0 20px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-direction: column;
    margin-top: 30px;
    padding: 0 15px;
  }
`;

export const InfoIconCardItem = styled.li`
  flex: 1 1 0%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const IconWrapper = styled.div`
  width: fit-content;
  height: fit-content;

  & svg {
    width: 30px;
    height: 30px;
  }
  & path {
    stroke: #6f8b90;
  }
`;
