export const ROUTES = {
  HOME: '/',
  LOGIN: '/auth',
  SEARCH: '/search',
  ACCOUNT: '/account',
  ACCOUNT_EDIT: '/account/edit',
  ACCOUNT_ADD_VEHICLE: '/account/add-vehicle',
  OFFER_LISTING: '/offers',
  OFFER: '/offers/:id',
  SERVICES: '/services',
  CREATE_OFFER: '/services/create-offer',
  CREATE_REQUEST: '/services/create-request',
  USER_DETAILS: '/user/:id',
  MY_RIDES: '/rides',
  MY_RIDE_DETAILS: '/rides/:id',
};
