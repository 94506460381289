import styled from 'styled-components';
import { FONTS } from 'styles';
import { GREY_100, GREY_300, GREY_400, WHITE } from 'styles/colors';

export const HistoryListTitle = styled.h2`
  font-family: ${FONTS.Helvetica.regular};
  color: ${GREY_300};
  text-align: end;
  font-size: 0.875rem;
  margin-bottom: 10px;
  padding-top: 60px;
`;

export const HistoryList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const HistoryItem = styled.li`
  padding: 20px 15px;
  background-color: ${WHITE};
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 0px 18px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: ${GREY_100};
  }
`;

export const HistotyLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    & path {
      stroke: ${GREY_400};
    }
  }
`;

export const HistoryText = styled.p`
  font-size: 1rem;
  color: ${GREY_400};
  font-family: ${FONTS.Helvetica.medium};
`;
