import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const MAX_CONTENT_WIDTH = 1024;

export const PageContentWrapper = styled.div`
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin: 0 auto;
  padding: 60px 30px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 30px 15px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin: 0 auto;
`;
