import { createSlice } from '@reduxjs/toolkit';
import {
  MyOffer,
  MyOffersApiResponse,
  StateType,
  UserType,
} from 'common/types';
import { AsyncThunks } from '../thunks';
import { onError, onPending } from '../stateResults';

type AccountStateType = StateType<UserType | null> & {
  isLoggedIn: boolean;
  myOffers: MyOffersApiResponse;
  myOfferDetails: MyOffer | null;
  registrationResult: { status: string; message: string } | null;
  result: UserType | null;
  userId: string;
};

const initialState: AccountStateType = {
  error: null,
  isLoggedIn: false,
  myOffers: { offers: [], totalCount: 0 },
  myOfferDetails: null,
  pending: false,
  registrationResult: null,
  result: null,
  userId: '',
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.loginUser.pending, onPending);
    builder.addCase(AsyncThunks.loginUser.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;

      if (action.payload.accessToken) {
        state.userId = action.payload.userId;
        state.isLoggedIn = true;
      }
    });
    builder.addCase(AsyncThunks.loginUser.rejected, onError);

    builder.addCase(AsyncThunks.registerUser.pending, onPending);
    builder.addCase(AsyncThunks.registerUser.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      state.registrationResult = action.payload;
    });
    builder.addCase(AsyncThunks.registerUser.rejected, onError);

    builder.addCase(AsyncThunks.getMe.pending, onPending);
    builder.addCase(AsyncThunks.getMe.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      state.result = { ...state.result, ...action.payload };
    });
    builder.addCase(AsyncThunks.getMe.rejected, onError);

    builder.addCase(AsyncThunks.getMyOffers.pending, onPending);
    builder.addCase(AsyncThunks.getMyOffers.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      state.myOffers = action.payload;
    });
    builder.addCase(AsyncThunks.getMyOffers.rejected, onError);

    builder.addCase(AsyncThunks.getMyOfferDetails.pending, onPending);
    builder.addCase(
      AsyncThunks.getMyOfferDetails.fulfilled,
      (state, action) => {
        state.pending = false;
        state.error = null;
        state.myOfferDetails = action.payload;
      },
    );
    builder.addCase(AsyncThunks.getMyOfferDetails.rejected, onError);

    builder.addCase(AsyncThunks.updateUser.pending, onPending);
    builder.addCase(AsyncThunks.updateUser.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      state.result = action.payload;
    });
    builder.addCase(AsyncThunks.updateUser.rejected, onError);

    builder.addCase(AsyncThunks.getUserVehicle.pending, onPending);
    builder.addCase(AsyncThunks.getUserVehicle.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      if (state.result) {
        state.result.vehicle = action.payload;
      }
    });
    builder.addCase(AsyncThunks.getUserVehicle.rejected, onError);

    builder.addCase(AsyncThunks.addVehicle.pending, onPending);
    builder.addCase(AsyncThunks.addVehicle.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      if (state.result) {
        state.result.vehicle = action.payload;
      }
    });
    builder.addCase(AsyncThunks.addVehicle.rejected, onError);
  },
});

export const accountActions = accountSlice.actions;
export const accountReducer = accountSlice.reducer;
