import { Environment } from '../common/types';

export const getEnvironment = (): Environment =>
  (process.env.REACT_APP_ENV as Environment) || Environment.DEVELOPMENT;

export const getApiUrl = (): string => {
  // const version = apiVersion || API_VERSION
  const environment = getEnvironment();

  switch (environment) {
    case Environment.DEVELOPMENT:
      return 'http://localhost:8080';
    case Environment.PRODUCTION:
      return 'https://api.taksifon.uz';
    default:
      return 'https://api.taksifon.uz';
  }
};
