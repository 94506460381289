import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SearchOfferParamsType } from 'common/types';
import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import {
  getOfferLoader,
  getOfferSearchHistoryList,
} from 'common/store/selectors';
import { useGoogleAnalytics } from 'hooks';
import SearchOfferForm from 'components/Forms/SearchOfferForm';
import { PageTemplate, Seo } from 'components';
import { ChevronRightIcon, ClockHistoryIcon } from 'assets/svg';
import { ROUTES } from 'router';
import {
  HistoryText,
  HistoryItem,
  HistoryList,
  HistoryListTitle,
  HistotyLabelWrapper,
} from './styles';

const SearchPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSearchLoading = useSelector(getOfferLoader);
  const searchHistory = useSelector(getOfferSearchHistoryList);
  const { addPageView } = useGoogleAnalytics();
  const { t } = useTranslation();

  const handleFormSubmit = async (params: SearchOfferParamsType) => {
    const response = await dispatch(AsyncThunks.searchOffers(params));
    if (response?.payload) {
      const queryParams = new URLSearchParams(params).toString();
      navigate(`${ROUTES.OFFER_LISTING}/?${queryParams}`, {
        state: { fromSearch: true },
      });
    }
  };

  useEffect(() => {
    addPageView('Search');
  }, []);

  return (
    <PageTemplate
      title={t('Find reliable and affordable taxis between cities')}
    >
      <Seo
        title='Qidiruv | Taksifon'
        description="Qidiruv sahifasi. Kerakli yo'nalish va vaqtni tanlash orqali taksilarni toping."
      />
      <SearchOfferForm
        isLoading={isSearchLoading}
        handleFormSubmit={handleFormSubmit}
      />

      {searchHistory?.length ? (
        <>
          <HistoryListTitle>{t('Search history')}</HistoryListTitle>
          <HistoryList>
            {searchHistory.map((history, index) => {
              const pickupRegion = history.pickupLocation.district;
              const dropoffRegion = history.dropoffLocation.district;

              return (
                <HistoryItem
                  key={index}
                  onClick={() =>
                    handleFormSubmit({
                      pickupLocationId: history.pickupLocation.id,
                      dropoffLocationId: history.dropoffLocation.id,
                      pickupTime: new Date().toISOString(),
                      requestedSeats: '1',
                    })
                  }
                >
                  <HistotyLabelWrapper>
                    <ClockHistoryIcon />
                    <HistoryText>
                      {t(`${pickupRegion}`)} - {t(`${dropoffRegion}`)}
                    </HistoryText>
                  </HistotyLabelWrapper>

                  <ChevronRightIcon />
                </HistoryItem>
              );
            })}
          </HistoryList>
        </>
      ) : null}
    </PageTemplate>
  );
};

export default SearchPage;
