import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import {
  getAccountId,
  getBookingError,
  getBookingLoader,
  getOffer,
  getOfferError,
  getOfferLoader,
} from 'common/store/selectors';
import {
  formatDateWithDayAndWeekday,
  formatPrice,
  formatTime,
} from 'helpers/date';
import { getUserRole } from 'helpers/utils';
import { GREY_100, PRIMARY_BLUE_100, WHITE } from 'styles/colors';
import { ROUTES } from 'router';
import {
  CustomButton,
  Divider,
  DriverDetailsCard,
  PageTemplate,
  RouteSummary,
  Seo,
  Typography,
} from 'components';

const OfferPage = () => {
  const { id: offerId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const offer = useSelector(getOffer);
  const userId = useSelector(getAccountId);
  const error = useSelector(getOfferError);
  const bookingError = useSelector(getBookingError);
  const bookingLoading = useSelector(getBookingLoader);
  const isLoading = useSelector(getOfferLoader);

  if (!offerId) return null;

  const isOwnOffer = offer?.driverId === userId;
  const hasJoinedAsPassenger = offer?.passengers.some((p) => p.id === userId);

  const fetchOfferDetails = async () => {
    await dispatch(AsyncThunks.getOfferDetails(offerId));
  };

  const bookOffer = async () => {
    await dispatch(
      AsyncThunks.makeBooking({
        offerId,
        requestedSeats: Number(searchParams.get('requestedSeats')),
        fromPlaceId: searchParams.get('pickupLocationId') || '',
        toPlaceId: searchParams.get('dropoffLocationId') || '',
      }),
    );
  };

  useEffect(() => {
    fetchOfferDetails();
  }, [offerId]);

  return (
    <PageTemplate
      title={formatDateWithDayAndWeekday(offer?.departureTime)}
      isLoading={isLoading}
      showBackButton
    >
      <Seo title='Taklif | Taksifon' description="Taklif haqida ma'lumotlar" />

      {error && <Typography color='red'>{error.message}</Typography>}
      {bookingError && (
        <Typography color='red'>{bookingError.message}</Typography>
      )}

      {offer && (
        <RouteSummary
          pickupLabel={offer.departure?.district}
          pickupSecondaryLabel={offer.departure.region}
          onPressPickup={() => {}}
          onPressDropoff={() => {}}
          dropoffLabel={offer.arrival?.district}
          dropoffSecondaryLabel={offer.arrival.region}
          formattedDepartureTime={formatTime(offer.departureTime)}
        />
      )}

      <Divider paddingVertical={20} color={GREY_100} />

      <div style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}>
        <Typography color='secondary' type='subtitle3' style={{ flexGrow: 1 }}>
          Narx 1 kishiga
        </Typography>
        <Typography type='title3'>
          {formatPrice(offer?.price.amount || 0)}
        </Typography>
      </div>

      <Divider paddingVertical={20} color={GREY_100} />

      <Typography type='title2'>Haydovchi</Typography>
      {offer?.driver && (
        <div style={{ padding: 10, backgroundColor: WHITE }}>
          <DriverDetailsCard
            avatar={offer.driver.photo || ''}
            displayName={offer.driver.firstName}
            description={getUserRole(offer.driver.role)}
            onClick={() => navigate(`/user/${offer.driverId}`)}
          />
        </div>
      )}

      <Divider paddingVertical={20} color={GREY_100} />
      <Typography type='title2'>Yo&apos;lovchilar</Typography>
      {offer?.passengers.map((passenger) => (
        <div
          key={passenger.id}
          style={{
            padding: 10,
            borderRadius: 8,
            backgroundColor: passenger.id === userId ? PRIMARY_BLUE_100 : WHITE,
          }}
        >
          <DriverDetailsCard
            avatar={passenger.photo || ''}
            displayName={passenger.firstName}
            onClick={() => navigate(`/user/${passenger.id}`)}
          />
        </div>
      ))}

      <Divider />

      <CustomButton
        disabled={hasJoinedAsPassenger}
        style={{ margin: 'auto' }}
        onClick={() => {
          if (isOwnOffer) {
            navigate(`${ROUTES.MY_RIDES}/${offerId}`);
          } else {
            bookOffer();
          }
        }}
        isLoading={bookingLoading}
      >
        {isOwnOffer && 'Batafsil'}
        {!isOwnOffer && !hasJoinedAsPassenger && 'Buyurtma qilish'}
        {!isOwnOffer && hasJoinedAsPassenger && 'Allaqachon buyurtma qilingan'}
      </CustomButton>
    </PageTemplate>
  );
};

export default OfferPage;
