import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'common/store';
import { modalActions } from 'common/store/slices';
import { ROUTES } from 'router';
import {
  AddVehicleContent,
  ChooseLanguageContent,
  CreateOfferSuccessContent,
  CreateRequestSuccessContent,
} from 'components/ModalContents';
import { WHITE } from 'styles/colors';

export const boxStyle: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  backgroundColor: `${WHITE}`,
};

const useModals = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const closeModal = () => {
    dispatch(modalActions.hideAllModals());
  };

  const showAddVehicleModal = () => {
    dispatch(
      modalActions.showModal({
        id: 'ADD_VEHICLE_MODAL',
        modalContent: <AddVehicleContent closeModal={closeModal} />,
        boxStyle: boxStyle,
      }),
    );
  };

  const showChooseLanguageModal = () => {
    dispatch(
      modalActions.showModal({
        id: 'CHOOSE_LANGUAGE_MODAL',
        modalContent: <ChooseLanguageContent closeModal={closeModal} />,
        boxStyle: boxStyle,
      }),
    );
  };

  const showCreateOfferSuccessModal = () => {
    dispatch(
      modalActions.showModal({
        id: 'OFFER_SUCCESS_MODAL',
        modalContent: <CreateOfferSuccessContent closeModal={closeModal} />,
        boxStyle: boxStyle,
        actionOnClose: () => {
          navigate(ROUTES.SERVICES);
        },
      }),
    );
  };

  const showCreateRequestSuccessModal = () => {
    dispatch(
      modalActions.showModal({
        id: 'REQUEST_SUCCESS_MODAL',
        modalContent: <CreateRequestSuccessContent closeModal={closeModal} />,
        boxStyle: boxStyle,
        actionOnClose: () => {
          navigate(ROUTES.SERVICES);
        },
      }),
    );
  };

  return {
    showAddVehicleModal,
    showChooseLanguageModal,
    showCreateOfferSuccessModal,
    showCreateRequestSuccessModal,
  };
};

export default useModals;
