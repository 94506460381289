import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  getOfferSearchList,
  getOfferSearchListLoader,
} from 'common/store/selectors';
import { AsyncThunks } from 'common/store/thunks';
import { useAppDispatch } from 'common/store';
import { usePagination } from 'hooks';
import { formatDate, getNextSevenDays, paramsToObject } from 'helpers/date';
import { GREY_300 } from 'styles/colors';
import { ROUTES } from 'router';
import {
  Loader,
  SearchOfferItem,
  PageTemplate,
  Seo,
  Typography,
} from 'components';
import {
  DateSection,
  DateTabsWrapper,
  DateTab,
  OfferListWrapper,
  OfferListingSummary,
} from './styles';

const PAGE_SIZE = 10;

const OfferListingPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { offers, totalCount, meta } = useSelector(getOfferSearchList);
  const isLoading = useSelector(getOfferSearchListLoader);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDate = formatDate(searchParams.get('pickupTime'));
  const searchValues = paramsToObject(searchParams.entries());
  const activeTabRef = useRef<HTMLButtonElement | null>(null);
  const { currentPage } = usePagination();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSelect = (date: string) => {
    setSearchParams({ ...searchValues, pickupTime: date });
  };

  const handleNavigateOfferItem = (offerId: number) => {
    navigate({
      pathname: `${ROUTES.OFFER_LISTING}/${offerId}`,
      search: searchParams.toString(),
    });
  };

  const searchOffers = useCallback(async () => {
    searchValues.offset = (currentPage - 1) * PAGE_SIZE;
    searchValues.limit = PAGE_SIZE;
    await dispatch(AsyncThunks.searchOffers(searchValues));
  }, [selectedDate]);

  const dates = useMemo(() => getNextSevenDays(), []);

  useEffect(() => {
    if (!location.state?.fromSearch) {
      searchOffers();
    }
  }, [selectedDate]);

  return (
    <PageTemplate style={{ padding: 0 }}>
      <Seo title='Takliflar | Taksifon' description='Takliflar' />
      <DateSection>
        <DateTabsWrapper>
          {dates.map((date, index) => {
            const isSelected = selectedDate === date.value;
            return (
              <DateTab
                key={index}
                ref={isSelected ? activeTabRef : null}
                isSelected={isSelected}
                onClick={() => handleSelect(date.value)}
              >
                {date.label}
              </DateTab>
            );
          })}
        </DateTabsWrapper>
      </DateSection>

      <OfferListingSummary>
        {!totalCount && !isLoading && (
          <Typography type='subtitle3'>
            {t('No offers found for this date')}
          </Typography>
        )}

        {meta && (
          <Typography
            type='subtitle4'
            style={{ color: GREY_300, marginTop: 10 }}
          >
            {meta.departure.district}, {meta.departure.region} →{' '}
            {meta.arrival.district}, {meta.arrival.region}
          </Typography>
        )}

        {!!totalCount && !isLoading && (
          <Typography
            type='subtitle4'
            style={{ color: GREY_300, marginTop: 8 }}
          >
            {totalCount} {t('rides available')}
          </Typography>
        )}
      </OfferListingSummary>

      <OfferListWrapper>
        {isLoading && <Loader />}

        {!isLoading &&
          offers.map((offer) => (
            <SearchOfferItem
              key={offer.id}
              offer={offer}
              onClick={() => handleNavigateOfferItem(offer.id)}
            />
          ))}
      </OfferListWrapper>
    </PageTemplate>
  );
};

export default OfferListingPage;
