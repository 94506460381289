import styled from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS } from 'styles';
import { WHITE } from 'styles/colors';

export const ServicesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ServiceItem = styled.li`
  width: 49%;
  padding: 14px 10px;
  background-color: ${WHITE};
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 0px 20px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
  }
`;

export const ServiceItemImage = styled.img`
  width: 30%;
  height: 50px;
  object-fit: contain;
  margin-right: 10px;
`;

export const ServiceItemTitle = styled(Typography).attrs({ type: 'title4' })`
  text-transform: uppercase;
`;
