import { UserPreference } from 'common/types';
import ItemHorizontal from 'components/ItemHorizontal/ItemHorizontal';
import { DialogIcon, MusicIcon, ZapIcon } from 'assets/svg';
import { getPreferenceLabel } from './UserPreferences.services';
import { ListItem } from './UserPreferences.styles';
import { GREY_300 } from 'styles/colors';

interface Props {
  smoking?: UserPreference;
  dialog?: UserPreference;
  music?: UserPreference;
}

const UserPreferences = ({ smoking, dialog, music }: Props) => {
  if (!smoking && !dialog && !music) return null;

  return (
    <ul>
      {music && (
        <ListItem>
          <ItemHorizontal
            IconLeft={<MusicIcon />}
            iconColor={GREY_300}
            label={getPreferenceLabel('music', music)}
          />
        </ListItem>
      )}
      {dialog && (
        <ListItem>
          <ItemHorizontal
            IconLeft={<DialogIcon />}
            iconColor={GREY_300}
            label={getPreferenceLabel('dialog', dialog)}
          />
        </ListItem>
      )}
      {smoking && (
        <ListItem>
          <ItemHorizontal
            IconLeft={<ZapIcon />}
            iconColor={GREY_300}
            label={getPreferenceLabel('smoking', smoking)}
          />
        </ListItem>
      )}
    </ul>
  );
};

export default UserPreferences;
