import { createSlice } from '@reduxjs/toolkit';

import { AsyncThunks } from '../thunks';
import { onError, onPending } from '../stateResults';
import { RequestType, StateType } from 'common/types';

type RequestStateType = StateType & {
  requestDetails: RequestType | null;
  requestList: RequestType[];
};

const initialState: RequestStateType = {
  pending: false,
  error: null,
  result: null,
  requestDetails: null,
  requestList: [],
};

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.createRequest.pending, onPending);
    builder.addCase(AsyncThunks.createRequest.fulfilled, (state, action) => {
      state.pending = false;
      state.requestDetails = action.payload;
    });
    builder.addCase(AsyncThunks.createRequest.rejected, onError);
  },
});

export const requestActions = requestSlice.actions;
export const requestReducer = requestSlice.reducer;
