import styled from 'styled-components';
import Typography from 'components/Typography/Typography';
import { WHITE } from 'styles/colors';

export const CardWrapper = styled.div`
  width: 100%;
  padding: 16px 20px;
  background-color: ${WHITE};
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NameTitle = styled(Typography).attrs({ type: 'subtitle1' })`
  text-transform: capitalize;
  margin-bottom: 10px;
`;
