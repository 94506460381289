import { Image, Wrapper } from './EmptyPageBanner.styles';
import Typography from 'components/Typography/Typography';

interface Props {
  title: string;
  description?: string;
  imageSrc: any;
}

const EmptyPageBanner = ({ title, description, imageSrc }: Props) => {
  return (
    <Wrapper>
      <Image src={imageSrc} />
      <Typography type='subtitle1'>{title}</Typography>
      <Typography type='subtitle3'>{description}</Typography>
    </Wrapper>
  );
};

export default EmptyPageBanner;
