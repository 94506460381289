import styled, { DefaultTheme, ThemeProps } from 'styled-components';
import { PRIMARY_BLUE_300, WHITE } from 'styles/colors';
import { FONTS } from 'styles';
import { ButtonSize } from './CustomButton';

interface StyledButtonProps {
  size: ButtonSize;
  inverted?: boolean;
  fullWidth?: boolean;
}

export const ButtonWrapper = styled.button<
  StyledButtonProps & ThemeProps<DefaultTheme>
>`
  ${({ fullWidth }) =>
    fullWidth ? 'width: 100%;' : 'width: auto; display: inline-block;'}

  padding: ${({ theme, size }) => theme.buttonSizes[size].padding};
  height: ${({ theme, size }) => theme.buttonSizes[size].height};
  display: flex;
  outline: none;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: ${({ inverted }) => (inverted ? WHITE : PRIMARY_BLUE_300)};
  border-color: ${PRIMARY_BLUE_300};
  border-width: 1px;
  border-style: solid;
  transition: 0.4s;

  & p {
    font-size: ${({ theme, size }) => theme.buttonSizes[size].fontSize};
    color: ${({ inverted }) => (inverted ? PRIMARY_BLUE_300 : WHITE)};
    font-family: ${FONTS.Helvetica.bold};
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    opacity: 0.8;
  }
`;
