import { StateType } from '../types';

export const onPending = (state: StateType) => {
  state.pending = true;
  state.error = null;
};

export const onFulfilled = (state: StateType, action: { payload: any }) => {
  state.pending = false;
  state.result = action.payload;
  state.error = null;
};

export const onError = (state: StateType, action: { payload: any }) => {
  state.pending = false;
  state.error = action.payload;
};

export const resetState = (state: StateType): void => {
  state.pending = false;
  state.result = null;
  state.error = null;
};
