import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const Wrapper = styled.div`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.xs}px) {
    text-align: center;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-height: 50vh;
  object-fit: contain;
  margin: 0 auto;
`;
