import React from 'react';
import { ErrorMessage, FormTitle, FormWrapper } from './FormTemplate.styles';

interface Props {
  title?: string;
  children: React.ReactNode;
  errorMessage?: string;
  showShadow?: boolean;
  onSubmit: (e: any) => Promise<void>;
}

const FormTemplate = ({
  title,
  errorMessage,
  onSubmit,
  showShadow,
  children,
}: Props) => {
  return (
    <FormWrapper showShadow={showShadow} onSubmit={onSubmit}>
      {title && <FormTitle>{title}</FormTitle>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {children}
    </FormWrapper>
  );
};

export default FormTemplate;
