import { createSlice } from '@reduxjs/toolkit';
import { StateType } from 'common/types';
import { AsyncThunks } from '../thunks';
import { onError, onPending } from '../stateResults';

const initialState: StateType = {
  pending: false,
  error: null,
  result: null,
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.makeBooking.pending, onPending);
    builder.addCase(AsyncThunks.makeBooking.fulfilled, (state) => {
      state.pending = false;
    });
    builder.addCase(AsyncThunks.makeBooking.rejected, onError);

    builder.addCase(AsyncThunks.confirmBooking.pending, onPending);
    builder.addCase(AsyncThunks.confirmBooking.fulfilled, (state, action) => {
      state.pending = false;
      state.result = action.payload;
    });
    builder.addCase(AsyncThunks.confirmBooking.rejected, onError);

    builder.addCase(AsyncThunks.cancelBooking.pending, onPending);
    builder.addCase(AsyncThunks.cancelBooking.fulfilled, (state) => {
      state.pending = false;
    });
    builder.addCase(AsyncThunks.cancelBooking.rejected, onError);
  },
});

export const bookingActions = bookingSlice.actions;
export const bookingReducer = bookingSlice.reducer;
