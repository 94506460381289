import { AvatarIcon } from 'assets/svg';
import { AvatarImage, AvatarWrapper } from './styles';

const Avatar = ({ size, src }: { src?: string | null; size?: string }) => {
  return (
    <AvatarWrapper size={size}>
      {src ? <AvatarImage src={src} alt='user image' /> : <AvatarIcon />}
    </AvatarWrapper>
  );
};

export default Avatar;
