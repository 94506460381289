import styled from 'styled-components';

export const ModalBackground = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #00000040;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;

  // centering modal
  display: flex;
  justify-content: flex-end;
`;

export const ModalWrapper = styled.div`
  background-color: white;
  width: fit-content;
  height: fit-content;
  min-height: 100px;
  opacity: 1;
  padding: 20px 24px;
  border-radius: 4px;
`;
