import * as yup from 'yup';
import dayjs from 'dayjs';

export const registrationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('Ism kiritish shart')
    .matches(/^[a-zA-Z ]+$/, 'Faqat harf kiritish mumkin')
    .min(3, 'Eng kamida 3ta harf kiritish shart')
    .max(20, "Eng ko'pi 20ta harf kiritish shart"),
  lastName: yup
    .string()
    .required('Familiya kiritish shart')
    .matches(/^[a-zA-Z ]+$/, 'Faqat harf kiritish mumkin')
    .min(3, 'Eng kamida 3ta harf kiritish shart')
    .max(20, "Eng ko'pi 20ta harf kiritish shart"),
  phoneNumber: yup
    .string()
    .required('Telefon raqam kiritish shart')
    .matches(/^[0-9]+$/, 'Faqat raqam kiritish mumkin')
    .length(9, "9ta raqamdan iborat bo'lishi shart, masalan: 901234567")
    .matches(/^(90|91|93|94|99|77|95|66|88)[0-9]{7}$/, "Noto'g'ri raqam"),
  // birthDate: yup
  //   .string()
  //   .required("Tug'ilgan sanani kiritish shart")
  //   .test('valid-date', "Noto'g'ri sana kiritildi", (value) => {
  //     return dayjs(value).isValid();
  //   }),
  password: yup
    .string()
    .min(8, 'Parol eng kamida 8ta belgidan iborat bo`lishi shart')
    .required('Parolni kiritish shart'),
  confirmPassword: yup
    .string()
    .required('Parolni qayta kiritish shart')
    .oneOf([yup.ref('password')], 'Parollar mos kelishi shart'),
});

export const updateUserSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('Ism kiritish shart')
    .matches(/^[a-zA-Z ]+$/, 'Faqat harf kiritish mumkin')
    .min(3, 'Eng kamida 3ta harf kiritish shart')
    .max(20, "Eng ko'pi 20ta harf kiritish shart"),
  lastName: yup
    .string()
    .required('Familiya kiritish shart')
    .matches(/^[a-zA-Z ]+$/, 'Faqat harf kiritish mumkin')
    .min(3, 'Eng kamida 3ta harf kiritish shart')
    .max(20, "Eng ko'pi 20ta harf kiritish shart"),
  phoneNumber: yup
    .string()
    .required('Telefon raqam kiritish shart')
    .matches(/^[0-9]+$/, 'Faqat raqam kiritish mumkin')
    .length(9, "9ta raqamdan iborat bo'lishi shart, masalan: 901234567")
    .matches(/^(90|91|93|94|99|77|95|66|88)[0-9]{7}$/, "Noto'g'ri raqam"),
  birthDate: yup
    .string()
    .required("Tug'ilgan sanani kiritish shart")
    .test('valid-date', "Noto'g'ri sana kiritildi", (value) => {
      return dayjs(value).isValid();
    }),
});

export const loginSchema = yup.object().shape({
  phoneNumber: yup.string().required('Telefon raqam kiritish shart'),
  password: yup.string().required('Parol kiritish shart'),
});

export const createOfferSchema = yup.object().shape({
  pickupLocationId: yup.string().required('Manzilni kiritish shart'),
  dropoffLocationId: yup
    .string()
    .required('Manzilni kiritish shart')
    .test(
      'same-as-pickup',
      "Manzillar bir xil bo'lmasligi shart",
      (value, ctx) => {
        return value !== ctx.parent.pickupLocationId;
      },
    ),
  pickupTime: yup.string().required('Sana kiritish shart'),
  fare: yup
    .number()
    .typeError('Faqat raqam kiritish mumkin')
    .required('Narx kiritish shart')
    .test('max-fare', "Narx 1mln so'mdan oshishi mumkin emas", (value) => {
      return value < 1000000;
    }),
  availableSeats: yup
    .number()
    .required("Bo'sh joylar sonini kiritish shart")
    .test('max-seats', "Bo'sh joylar soni 9dan oshmasligi shart", (value) => {
      return value <= 9;
    }),
});

export const createRequestSchema = yup.object().shape({
  fromPlaceId: yup.string().required('Manzilni kiritish shart'),
  toPlaceId: yup
    .string()
    .required('Manzilni kiritish shart')
    .test(
      'same-as-pickup',
      "Manzillar bir xil bo'lmasligi shart",
      (value, ctx) => {
        return value !== ctx.parent.pickupDistrictId;
      },
    ),
  departureTime: yup.string().required('Sana kiritish shart'),
  numberOfPassengers: yup
    .number()
    .required("Yo'lovchilar sonini kiritish shart")
    .test(
      'max-passengers',
      "Yo'lovchilar soni 30dan oshmasligi shart",
      (value) => {
        return value <= 30;
      },
    ),
});

export const addVehicleSchema = yup.object().shape({
  model: yup.string().required('Mashina modelini kiritish shart'),
  number: yup.string().required('Mashina raqamini kiritish shart'),
  images: yup
    .mixed()
    .test(
      'min-files',
      'Kamida 1ta rasm yuklash shart',
      (value: any) => value.length >= 1,
    )
    .test(
      'max-files',
      'Rasmlar 5tadan oshmasligi shart',
      (value: any) => value.length <= 5,
    ),
});
