import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useAppDispatch } from 'common/store';
import { AsyncThunks } from 'common/store/thunks';
import { getOfferError, getOfferLoader } from 'common/store/selectors';
import { useModals, useDistrictOptions } from 'hooks';
import { createOfferSchema } from 'helpers/validationSchemas';
import { CreateOfferFormFieldsType } from 'common/types';
import { CustomButton, FormTemplate, PageTemplate, Seo } from 'components';
import {
  LocationAutocomplete,
  BasicDateTimePicker,
  FormInputController,
  CounterInputController,
} from 'components/Inputs';

const CreateOfferPage = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(getOfferLoader);
  const error = useSelector(getOfferError);
  const { showCreateOfferSuccessModal } = useModals();
  const { t } = useTranslation();

  const defaultValues: CreateOfferFormFieldsType = {
    pickupLocationId: '',
    dropoffLocationId: '',
    pickupTime: '',
    availableSeats: 1,
    fare: 0,
  };

  const methods = useForm({
    resolver: yupResolver(createOfferSchema),
    defaultValues,
  });

  const { control, handleSubmit, watch } = methods;

  const { pickupLocationId, dropoffLocationId } = watch();

  const { filteredDropoff, filteredPickup } = useDistrictOptions({
    dropoffLocationId,
    pickupLocationId,
  });

  const maxSelectableDate = dayjs(new Date()).add(6, 'day');

  const onSubmit: SubmitHandler<CreateOfferFormFieldsType> = async (
    data: CreateOfferFormFieldsType,
    e: any,
  ) => {
    if (data) {
      const response = await dispatch(AsyncThunks.createOffer(data));
      if (!response.payload?.error) {
        showCreateOfferSuccessModal();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        methods.reset();
      }
    }
    e.target.reset();
  };

  return (
    <PageTemplate title={t('Need a passenger')} showBackButton>
      <Seo
        title='Taklif yaratish | Taksifon'
        description="Yo'lovchilarga o'z xizmatingizni taklif qiling. Biz bilan hammasi tez, qulay va oson!"
      />
      <FormTemplate
        onSubmit={(e) => handleSubmit(onSubmit)(e)}
        errorMessage={error?.message}
      >
        <LocationAutocomplete
          id='location-from'
          name='pickupLocationId'
          label={t('Pickup location')}
          control={control}
          options={filteredPickup}
        />
        <LocationAutocomplete
          id='location-to'
          name='dropoffLocationId'
          label={t('Dropoff location')}
          control={control}
          options={filteredDropoff}
        />

        <div style={{ display: 'flex', gap: 10 }}>
          <BasicDateTimePicker
            control={control}
            name='pickupTime'
            label={t('Pickup time')}
            dateProps={{
              disablePast: true,
              maxDate: maxSelectableDate,
            }}
          />
          <FormInputController
            control={control}
            name='fare'
            type='number'
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            label={t('Price (1 passenger)')}
          />
        </div>
        <CounterInputController
          control={control}
          name='availableSeats'
          label={t('Number of free seats')}
        />

        <CustomButton
          type='submit'
          isLoading={isLoading}
          style={{ marginTop: 10 }}
        >
          {t('Confirm')}
        </CustomButton>
      </FormTemplate>
    </PageTemplate>
  );
};

export default CreateOfferPage;
