import { Dispatch, SetStateAction, useState } from 'react';
import Typography from 'components/Typography/Typography';
import CustomButton from 'components/Buttons/CustomButton';
import {
  CounterButton,
  CounterContainer,
  CounterCount,
  Wrapper,
} from './PassengersCount.styles';

interface Props {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  closeModal: () => void;
}

const PassengersCounter = ({ value, setValue, closeModal }: Props) => {
  const [requestedSeats, setRequestedSeats] = useState(value);
  return (
    <Wrapper>
      <Typography type='title1' style={{ textAlign: 'center' }}>
        Yo&apos;lovchilar soni
      </Typography>

      <CounterContainer>
        <CounterButton
          disabled={requestedSeats === 1}
          onClick={() => {
            setValue((prev) => prev - 1);
            setRequestedSeats((prev) => prev - 1);
          }}
        >
          -
        </CounterButton>

        <CounterCount>
          <Typography type='title1'>{requestedSeats}</Typography>
        </CounterCount>

        <CounterButton
          disabled={requestedSeats === 9}
          onClick={() => {
            setValue((prev) => prev + 1);
            setRequestedSeats((prev) => prev + 1);
          }}
        >
          +
        </CounterButton>
      </CounterContainer>
      <CustomButton onClick={closeModal}>Davom etish</CustomButton>
    </Wrapper>
  );
};

export default PassengersCounter;
